import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'
import { Button, Price } from '@openbox-app-shared'
import dashboardIcons from '../../../../icons'

const StyledCart = styled.div`
  ul {
    list-style-type: none;
    li {
      position: relative;
      display: block;
      > * {
        padding-left: 0.75rem;
      }
      /* background-color: orange; */
      .master {
        padding-right: 7rem;
        background-color: #6C7A89;
        color: white;
        > * {
          display: block;
          min-height: 2.25rem;
          line-height: 1rem;
        }
        .product-name, .price, .quantity {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .product-name {
          /* background-color: skyblue; */
        }
        .quantity, .price {
          position: absolute;
          top: 0;
        }
        .quantity {
          width: 2.25rem;
          right: 4.75rem;
          text-align: center;
          background-color: #1A1F35;
          color: white;
        }
        .price {
          width: 4rem;
          right: 0.75rem;
          text-align: right;
          /* background-color: pink; */
        }
        .action-note, .action-void {
          position: absolute;
          top: 0;
          padding: 0;
          font-size: 200%;
          box-shadow: none;
          border-radius: 0;
          width: 2.25rem;
          height: 2.25rem;
        }
        .action-note {
          right: 2.25rem;
        }
        .action-void {
          right: 0;
        }
      }
      .master.can-void {
        padding-right: 9.25rem;
        .quantity {
          right: 7rem;
        }
        .price {
          right: 3rem;
        }
      }
      .master.can-void.can-note {
        padding-right: 11.5rem;
        .quantity {
          right: 9.25rem;
        }
        .price {
          right: 5.25rem;
        }
      }
      .questions {
        padding-left: 0;
        padding-right: 0.5rem;
        background-color: white;
        > * {
          padding-left: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 2rem;
          line-height: 0.75rem;
          font-size: 90%;
        }
        span {
          display: block;
        }
      }
    }
    .custom-note {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-right: 0.5rem;
      background-color: #f0f1f3;
      p {
        line-height: 1rem;
        font-size: 90%;
        color: black;
      }
    }
    li.is-compd {
      text-decoration: line-through;
    }
  }
  @media only print {
    .product-name {
      font-size: 90%;
    }
  }
`

export default function Cart ({ cart, canVoid = '#ff3838', onVoid, canNote = false, onNote }) {
  if (cart.length === 0) {
    return null
  }
  return (
    <StyledCart className='component--ops-manager-cart'>
      <ul>
        {cart
          .filter(ci => {
            return !(ci.productName || '').includes('Add a product before payment with the same name as the sticky')
          })
          .map((ci, fallbackIndex) => {
            const hasQuestions = ci.questions.length > 0
            const hasCustomNote = typeof ci.customNote === 'string' && ci.customNote.length > 0
            return (
              <li key={ci.key || `fallback--${fallbackIndex}`} className={classnames({ 'has-questions': hasQuestions, 'is-compd': ci.isCompd })}>
                <div className={classnames('master', { 'can-void': canVoid, 'can-note': canNote })}>
                  <span className='product-name'><strong>{ci.productName || (ci.product ? ci.product.name : 'Unknown product')}</strong></span>
                  <strong className='quantity'>
                    {!ci.quantitySymbolOverride && ci.quantity}
                    {ci.quantitySymbolOverride && ci.quantitySymbolOverride}
                  </strong>
                  <strong className='price'><Price currency={ci.productCurrency} price={ci.productPrice * ci.quantity} /></strong>
                  {canNote && <Button disabled={ci.key.startsWith('bodge--')} className='action-note' backgroundColor='#f0f1f3' color={ci.key.startsWith('bodge--') ? '#f0f1f3' : 'black'} onClick={() => onNote && onNote(ci)} InlineIcon={dashboardIcons.note} />}
                  {canVoid && <Button className='action-void' backgroundColor={canVoid} onClick={() => onVoid && onVoid(ci)}>×</Button>}
                </div>
                {hasCustomNote && <div className='custom-note'>
                  <p>{ci.customNote}</p>
                </div>}
                {hasQuestions && <div className='questions'>
                  {ci.questions
                    .filter(q => Array.isArray(q.answer) ? q.answer.length > 0 : q.answer)
                    .map((q, i) => {
                      return (
                        <div key={`${i}--${q.question}--${q.answer}`}>
                          <span>{q.question} <strong>{Array.isArray(q.answer) ? q.answer.join(', ') : q.answer}</strong></span>
                        </div>
                      )
                    })}
                </div>}
              </li>
            )
          })
        }
      </ul>
    </StyledCart>
  )
}

Cart.propTypes = {
  cart: PropTypes.arrayOf(PropTypes.object).isRequired,
  canVoid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onVoid: PropTypes.func,

  canNote: PropTypes.bool,
  onNote: PropTypes.func,
}
