import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import H2 from './H2'

import { Button, Input } from '@openbox-app-shared'
import dashboardIcons from '../icons'

const WAIT = 1 * 1000

const StyledComponent = styled.div`
  > * {
    text-align: center;
  }
  > * + * {
    margin-top: 1rem;
  }
  .component--h2 {
    line-height: 1.5rem;
  }
  .component--input {
    font-size: 100%;
    input {
      height: 2.5rem;
    }
  }
  .component--button {
    margin-left: auto;
    margin-right: auto;
  }
`
export default function CopyUrl ({ url, children, buttonProps = {} }) {
  const [buttonText, setButtonText] = useState('Copy link')
  let timeout
  useEffect(
    () => {
      return () => {
        clearTimeout(timeout)
      }
    },
    []
  )
  return (
    <StyledComponent className='component--copy-url'>
      {children && <H2>{children}</H2>}
      <Input value={url} selectAll />
      <Button
        InlineIcon={dashboardIcons.copy}
        onClick={() => {
          setButtonText('Copied!')
          timeout = setTimeout(() => setButtonText('Copy link'), WAIT)
          navigator.clipboard.writeText(url)
        }}
        {...buttonProps}
      >
        {buttonText}
      </Button>
    </StyledComponent>
  )
}
CopyUrl.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node,
  buttonProps: PropTypes.object
}
