/* eslint-disable react/prop-types */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { scrollToBottom, scrollToTop, CustomHelmet } from '@openbox-app-shared'

import Box from '../../components/Box'
import TabBar from '../../components/TabBar'
import MainFrame from '../../components/MainFrame'
import User from '../../components/User'
import ProductCategoryModal from '../../components/modals/ProductCategory'

import { log } from '../../log'
import _ from '../../_'
import { dispatch, subscribe } from '../../redux'

import tabThings from './tabs/things'
import tabApplications from './tabs/applications'
import tabProductsCategories from './tabs/productsAndCategories/productsAndCategories'
import tabFederatedUsers from './tabs/federatedUsers'
import HeaderBar from '../../components/HeaderBar'
import dashboardIcons from '../../icons'

const StyledRoute = styled.div`
  padding: 1rem;
  .to-do-list {
    .component--h1 {
      color: #6c7a89;
    }
    > * {
      margin-top: 1rem;
    }
  }
  .component--h2 + .component--form, .component--h2 + .component--input {
    margin-top: 0.5rem;
  }
  .component--switch + * {
    margin-top: 1rem;
  }
  .component--box {
    > .component--details {
      width: 100%;
    }
  }
  .component--box.main {
    min-height: 24rem;
  }
  .component--banner + .component--box, .component--banner + .component--banner {
    margin-top: 1rem;
  }
  .main-dish-1 > .component--tabbar > .component--bar ul li a.active {
    color: white;
  }

  .main-dish-1 > .component--tabbar > .component--bar ul li.tab-v2-flows a {
    color: #26de81;
  }
  .main-dish-1 > .component--tabbar > .component--bar ul li.tab-v2-flows a.active {
    border-color: #26de81;
    background-color: transparent;
  }

  .main-dish-1 > .component--tabbar > .component--bar ul li.tab-v2-stickies a {
    color: #f7b731;
  }
  .main-dish-1 > .component--tabbar > .component--bar ul li.tab-v2-stickies a.active {
    border-color: #f7b731;
    background-color: transparent;
  }
  .main-dish-1 {
    padding-top: 1rem;
  }
  .main-dish-1 > .component--tabbar > .component--bar {
    display: none;
  }
  .main-dish-1 > .component--tabbar > .component--bar ul li.tab-v2-products a {
    color: #a55eea;
  }
  .main-dish-1 > .component--tabbar > .component--bar ul li.tab-v2-products a.active {
    border-color: #a55eea;
    background-color: transparent;
  }

  .main-dish-1 > .component--tabbar > .component--bar ul li.tab-v2-team a {
    color: #4b7bec;
  }
  .main-dish-1 > .component--tabbar > .component--bar ul li.tab-v2-team a.active {
    border-color: #4b7bec;
    background-color: transparent;
  }
  .component--tabbar {
    .buttons {
      margin-bottom: 0;
      > * {
        display: inline-block;
        margin: 0 1rem 1rem 0;
        vertical-align: top;
      }
    }
    .component--banner {
      margin-top: 1rem;
    }
    .component--box + .component--box {
      margin-top: 1rem;
    }
  }

  .main-dish-2 {
    .component--list-item {
      &::before {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 2.5rem;
        background-color: #F0F1F3;
        height: 100%;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      > a, > span {
        padding-left: 1rem;
      }
    }
  }
  .main-dish-2.applications, .main-dish-2.things {
    .component--list-item {
      .right-side {
        margin-top: 0;
      }
      .component--tag-chooser-user {
        display: none;
        margin-left: 0.5rem;
      }
    }
  }
  .main-dish-2.things {
    .component--user-circle {
      margin-left: 0.75rem;
    }
  }
  @media only screen and (min-width: 832px) {
    .main-dish-2.applications, .main-dish-2.things {
      .component--list-item {
        .component--tag-chooser-user {
          display: block;
        }
      }
    }
  }
`

const tabs = [
  tabApplications,
  tabThings,
  tabProductsCategories,
  tabFederatedUsers
]

export default class Route extends Component {
  constructor () {
    super()
    this.state = {}
    this.createApplication = this.createApplication.bind(this)
    this.createThing = this.createThing.bind(this)
    this.createProduct = this.createProduct.bind(this)
    this.createProductCategory = this.createProductCategory.bind(this)
    this.createFederatedUser = this.createFederatedUser.bind(this)

    this.onProductCategoryEdit = this.onProductCategoryEdit.bind(this)

    this.onThingAction = this.onThingAction.bind(this)
    this.onApplicationAction = this.onApplicationAction.bind(this)
    this.onProductAction = this.onProductAction.bind(this)
    this.onProductCategoryAction = this.onProductCategoryAction.bind(this)
    this.refreshEverything = this.refreshEverything.bind(this)

    this.CHOOSE_APPLICATION_BASE_OR_BASE_SET_GOOD = this.CHOOSE_APPLICATION_BASE_OR_BASE_SET_GOOD.bind(this)
    this.NEW_BASE_PRODUCT_GOOD = this.NEW_BASE_PRODUCT_GOOD.bind(this)
    this.SURE_DELETE_GOOD = this.SURE_DELETE_GOOD.bind(this)
    this.GET_INPUT_GOOD = this.GET_INPUT_GOOD.bind(this)
    this.STOCK_GOOD = this.STOCK_GOOD.bind(this)
    this.PRODUCTS_IMPORT_GOOD = this.PRODUCTS_IMPORT_GOOD.bind(this)
    this.SHOW_MESSAGE_GOOD = this.SHOW_MESSAGE_GOOD.bind(this)
    this.REDUX_HANDLER_READY = this.REDUX_HANDLER_READY.bind(this)
  }

  async createThing (baseName = 'Sticky', howMany = 1) {
    dispatch('LOADING')

    const existingNames = this.state.things.map(_ => _.name)
    const makeNewName = _ => `${baseName} ${_}`

    let finalThingId
    try {
      for (let _ = 0; _ < howMany; _++) {
        let i = 1
        while (existingNames.includes(makeNewName(i))) i++
        const newName = makeNewName(i)
        existingNames.push(newName)
  
        const payload = {
          name: newName,
          applicationId: this.state.applications.length > 0 ? this.state.applications[0].id : undefined,
          location: this.state.things.length > 0 ? this.state.things[this.state.things.length - 1].location.toJson() : undefined,
          isVirtual: true,
          createdAt: _
        }
        log('[Route-user] [createThing]', { _, payload })
        finalThingId = (await window.sticky.things.create(payload)).id
      }
    } catch ({ message }) {
      window.sticky.applications.blocks.showError(message, true)
    } finally {
      dispatch('STOP_LOADING')
    }

    const things = await window.sticky.things.getAll()
    this.setState({ things })
    dispatch('STOP_LOADING')
    dispatch('BLINK', { ids: [finalThingId] })
  }

  async doCreateApplication (payload) {
    log('[Route-user] [doCreateApplication]', { payload })
    const { user } = this.props

    const maybePartnerName = user.partner ? user.partner.name : 'us'
    try {
      typeof user.limitApplications === 'number' && window.sticky.assert(
        this.state.applications.length < user.limitApplications,
        `You can only have ${user.limitApplications} flow${user.limitApplications !== 1 ? 's' : ''}.`
      )
    } catch ({ message }) {
      if (!user.isLoggedInAsPartner && user.can('hide-billing')) {
        dispatch(
          'SHOW_MESSAGE',
          {
            message: <>
              <p><strong>{message}</strong></p>
              <p>Please contact {maybePartnerName}.</p>
            </>,
            canBeBadded: ''
          }
        )
        return
      }
    }

    dispatch('LOADING')

    try {
      const newApplication = await window.sticky.applications.create(payload)

      if (user.federatedUserCan('lock--create-application', false)) {
        dispatch('SHOW_MESSAGE', { message: <p>Added.</p>, why: 'lock--create-application', canBeBadded: '', canBeGooded: 'Add another' })
        return
      }

      const applications = await window.sticky.applications.getAll()
      this.setState({ applications })
      dispatch('BLINK', { ids: [newApplication.id] })
    } catch (e) {
      dispatch('LETS_GET_THIS_BREAD', { user, message: e.message, tryingToExceed: true })
    } finally {
      dispatch('STOP_LOADING')
    }
  }

  async getNewApplicationPayloadFromBase (base, replaceProps = {}, overrideName) {
    const federatedUsers = await window.sticky.users.federated.getAll()

    // this ugly code came back the backend; just getting it all into one place...
    // it would be best to merge in the backend not the frontend, but you wouldn't have access to 'foundLastApplication' or 'replaceProps' trivially
    const { user } = this.props
    const tagIds = base.tagsV2ByName
      .map(_ => user.tags.get().find(userTag => userTag.name === _))
      .filter(_ => _)
      .map(_ => _.id)
    const foundLastApplication = this.state.applications.length > 0 ? this.state.applications[this.state.applications.length - 1] : undefined
    replaceProps = {
      ...replaceProps,
      'user.name': user.name || '(ENTER NAME)',
      'user.email': user.email || '(ENTER EMAIL)',
      'user.phone': user.phone || '(ENTER PHONE NUMBER)',
      'dashboardLevelMemory': user.customData.get(),
      'user.logoUrl': user.logoUrl,
      'user.privateKey': user.privateKey,
      'teamMembers': {},
      APPLICATION_PRIMARY_COLOR: foundLastApplication ? foundLastApplication.primaryColor : '#1A1F35',
      APPLICATION_BACKGROUND_COLOR: foundLastApplication ? foundLastApplication.backgroundColor : '#FFFFFF'
    }
    federatedUsers.forEach(fu => {
      replaceProps.teamMembers[fu.id] = fu.toSerialisedJson()
    })
    const baseCopyAsObject = window.eval(`(() => { const copy = ${JSON.stringify(base.copy)}; const inputFlowSteps = ${JSON.stringify(replaceProps)}; ${base.copyFunction} })();`)

    let finalName = overrideName || base.newName || base.name
    const similarlyNamedApplications = this.state.applications.filter(_ => _.name === finalName || _.name.startsWith(finalName + ' '))
    finalName += similarlyNamedApplications.length > 0 ? (' ' + (similarlyNamedApplications.length + 1).toString()) : ''
    const payload = {
      name: finalName,
      ...baseCopyAsObject,
      baseIcon: base.icon,
      baseSettingsRender: base.settingsRender,
      tags: tagIds
    }
    if (foundLastApplication) {
      payload.primaryColor = baseCopyAsObject.primaryColor || foundLastApplication.primaryColor
      payload.backgroundColor = baseCopyAsObject.backgroundColor || foundLastApplication.backgroundColor
      payload.location = foundLastApplication.location
      payload.lightMode = foundLastApplication.lightMode
      if (typeof baseCopyAsObject.lightMode === 'boolean') {
        payload.lightMode = baseCopyAsObject.lightMode
      }
    }
    return payload
  }

  async CHOOSE_APPLICATION_BASE_OR_BASE_SET_GOOD ({ type, what, config }) {
    log('[Route-user] [CHOOSE_APPLICATION_BASE_OR_BASE_SET_GOOD]', { type, what, config })

    type === 'ApplicationBase' && await (async () => {
      let payload
      try {
        payload = await this.getNewApplicationPayloadFromBase(what, config)
      } catch ({ message }) {
        window.sticky.applications.blocks.showError(`Check this flow template's "Copied data function".\n\nJavaScript said:\n\n${message}`, true)
        return
      }
      log('[Route-user] [CHOOSE_APPLICATION_BASE_OR_BASE_SET_GOOD] 2 payload', payload)
      await this.doCreateApplication(payload)
    })()

    // type === 'ApplicationBaseSet' && await (async () => {
    //   const { user } = this.props
    //   const payloads = config.map(({ applicationBase, config }) => await this.getNewApplicationPayloadFromBase(applicationBase, config))
    //   if (payloads.length === 0) {
    //     return
    //   }
    //   dispatch('LOADING')
    //   try {
    //     await window.sticky.forEach(payloads, async _ => {
    //       await window.sticky.applications.create(_)
    //     })
    //     const applications = await window.sticky.applications.getAll()
    //     this.setState({ applications })
    //     dispatch('BLINK', { ids: [applications[applications.length - 1].id] })
    //   } catch (e) {
    //     dispatch('LETS_GET_THIS_BREAD', { user, message: e.message, tryingToExceed: true })
    //   } finally {
    //     dispatch('STOP_LOADING')
    //   }
    // })()
  }

  async NEW_BASE_PRODUCT_GOOD ({ productCategory, why, bases }) {
    why === 'TabProductsCategoriesRenderChild' && await (async () => {
      dispatch('LOADING')
      const newProducts = await window.sticky.map(
        bases,
        base => {
          return this.doCreateProduct(
            productCategory,
            {
              ...base.copyJson,
              productBaseId: base.id
            },
            base.applicationBaseId
          )
        }
      )
      const applications = await window.sticky.applications.getAll()
      const products = await window.sticky.products.getAll()
      this.setState({
        products,
        applications
      })
      dispatch('STOP_LOADING')
      dispatch('BLINK', { ids: newProducts.map(_ => _.id) })
    })()
  }

  SURE_DELETE_GOOD ({ why, entity }) {
    log('[Route-user] [SURE_DELETE_GOOD]', { why, entity })
    why === 'thing' && (async () => {
      try {
        await window.sticky.things.remove(entity)
        const things = await window.sticky.things.getAll()
        this.setState({ things })
      } catch (e) {
        window.sticky.applications.blocks.showError('That didn\'t work. Refresh your dashboard.', true)
      }
    })()
    why === 'application' && (async () => {
      try {
        await window.sticky.applications.remove(entity)
        const applications = await window.sticky.applications.getAll()
        this.setState({ applications })
      } catch ({ message }) {
        window.sticky.applications.blocks.showError(message, true)
      }
    })()
    why === 'product' && (async () => {
      try {
        for (let i = 0; i < this.state.productCategories.length; i++) {
          const pc = this.state.productCategories[i]
          if (pc.products.has(entity.id)) {
            pc.products.delete(entity.id)
            window.sticky.products.categories.save(pc)
          }
        }
        await window.sticky.products.remove(entity)
        const products = await window.sticky.products.getAll()
        this.setState({ products })
      } catch (e) {
        window.sticky.applications.blocks.showError('That didn\'t work. Refresh your dashboard to update your products.', true)
      }
      window.parent.postMessage({ event: 'reloadProducts' })
    })()
    why === 'productCategory' && (async () => {
      try {
        let toDeleteProducts
        if (entity === undefined) {
          const productCategories = await window.sticky.products.categories.getAll()
          const products = await window.sticky.products.getAll()
          toDeleteProducts = products
            .filter(p => productCategories.every(pc => !pc.products.has(p.id)))
        } else {
          toDeleteProducts = Array.from(entity.products)
            .map(productId => this.state.products.find(p => p.id === productId))
            .filter(maybeProduct => maybeProduct) 
        }
        for (let i = 0; i < toDeleteProducts.length; i++) {
          await window.sticky.products.remove(toDeleteProducts[i])
        }
        entity && await window.sticky.products.categories.remove(entity)
        const productCategories = await window.sticky.products.categories.getAll()
        const products = await window.sticky.products.getAll()
        this.setState({ productCategories, products })
      } catch (e) {
        window.sticky.applications.blocks.showError('That didn\'t work. Refresh your dashboard to update your product categories.', true)
      }
      window.parent.postMessage({ event: 'reloadProducts' })
    })()
  }

  GET_INPUT_GOOD ({ why, entity, string }) {
    log('[Route-user] [GET_INPUT_GOOD]', { why, entity, string })
    why === 'thing' && (async () => {
      const oldName = entity.name
      try {
        entity.patch({ name: string })
        await window.sticky.things.save(entity)
      } catch ({ message }) {
        window.sticky.applications.blocks.showError(message, true)
        entity.patch({ name: oldName })
      } finally {
        this.forceUpdate()
      }
    })()
    why === 'application' && (async () => {
      const oldName = entity.name
      try {
        entity.patch({ name: string })
        await window.sticky.applications.save(entity)
      } catch ({ message }) {
        window.sticky.applications.blocks.showError(message, true)
        entity.patch({ name: oldName })
      } finally {
        this.forceUpdate()
      }
    })()
    why === 'product' && (() => {
      entity.patch({ name: string })
      this.forceUpdate()
      window.sticky.products.save(entity)
      window.parent.postMessage({ event: 'reloadProducts' })
    })()
    why === 'productCategoryNew' && (() => {
      (async () => {
        const { applications, productCategories: existingProductCategories } = this.state
        const pcColor = existingProductCategories.length === 0 ? (applications.length > 0 ? applications[0].backgroundColor : undefined) : undefined
        const pcForegroundColor = existingProductCategories.length === 0 ? (applications.length > 0 ? applications[0].primaryColor : undefined) : undefined
        dispatch('LOADING')
        const newProductCategory = await window.sticky.products.categories.create({ name: string, color: pcColor, foregroundColor: pcForegroundColor })
        const productCategories = await window.sticky.products.categories.getAll()
        this.setState({
          productCategories
        })
        dispatch('STOP_LOADING')
        dispatch('BLINK', { ids: [newProductCategory.id] })
        window.parent.postMessage({ event: 'reloadProducts' })
      })()
    })()
    why === 'productCategorySave' && (() => {
      entity.patch({ name: string })
      this.forceUpdate()
      window.sticky.products.categories.save(entity)
      window.parent.postMessage({ event: 'reloadProducts' })
    })()
    why === 'product--price' && (() => {
      entity.patch({ price: string })
      this.forceUpdate()
      window.sticky.products.save(entity, ['price'])
      window.parent.postMessage({ event: 'reloadProducts' })
    })()
    why === 'ImportProductsCategories' && (() => {
      window.parent.postMessage({ event: 'reloadProducts' })
      window.location.reload()
    })()
  }

  STOCK_GOOD ({ why, entity, stock, stockNotify }) {
    why === 'ProductListItem' && (() => {
      entity.patch({ stock, stockNotify })
      this.forceUpdate()
      window.sticky.products.save(entity, ['stock', 'stockNotify'])
      window.parent.postMessage({ event: 'reloadProducts' })
    })()
  }

  async PRODUCTS_IMPORT_GOOD ({ fromSdk }) {
    log('[Route-user] [PRODUCTS_IMPORT_GOOD]', { fromSdk })

    dispatch('LOADING')
    const { allProducts, productCategories } = fromSdk
    for (let pi = 0; pi < allProducts.length; pi++) {
      const maybeNew = allProducts[pi]
      let foundExistingEntity = this.state.products.find(p => p.name === maybeNew.name)
      if (foundExistingEntity) {
        foundExistingEntity.patch({ ...maybeNew })
        await window.sticky.products.save(foundExistingEntity)
        maybeNew.createdId = foundExistingEntity.id
      } else {
        const whatCreated = await window.sticky.products.create({ ...maybeNew })
        maybeNew.createdId = whatCreated.id
      }
    }

    for (let pi = 0; pi < productCategories.length; pi++) {
      const maybeNew = productCategories[pi]
      if (maybeNew.dontCreateCategory) {
        continue
      }
      const foundExistingEntity = this.state.productCategories.find(p => p.name === maybeNew.name)
      if (foundExistingEntity) {
        foundExistingEntity.patch({ ...maybeNew })
        foundExistingEntity.products.append(maybeNew.products.map(p => p.createdId))
        await window.sticky.products.categories.save(foundExistingEntity)
        maybeNew.createdId = foundExistingEntity.id
      } else {
        const whatCreated = await window.sticky.products.categories.create({ ...maybeNew, products: maybeNew.products.map(p => p.createdId) })
        maybeNew.createdId = whatCreated.id
      }
    }
    dispatch('STOP_LOADING')
  }

  async SHOW_MESSAGE_GOOD ({ why }) {
    why === 'lock--create-application' && this.createApplication()
    why === 'ImportProductsCategories' && await (async () => {
      const products = await window.sticky.products.getAll()
      const productCategories = await window.sticky.products.categories.getAll()
      this.setState({
        products,
        productCategories
      })
    })()
  }

  REDUX_HANDLER_READY () {
    const { autoUi, user } = this.props
    if (user.federatedUserCan('applications') && (autoUi.includes('createFlow') || user.federatedUserCan('lock--create-application', false))) {
      this.createApplication()
    }
  }

  async createApplication () {
    dispatch('CHOOSE_APPLICATION_BASE_OR_BASE_SET')
  }

  async doCreateProduct (productCategory, customPayload, applicationBaseId) {
    const payload = {
      location: this.state.products.length > 0 ? this.state.products[this.state.products.length - 1].location.toJson() : undefined,
      ...customPayload
    }
    const newProduct = await window.sticky.products.create(payload)
    if (productCategory) {
      productCategory.products.add(newProduct.id)
      window.sticky.products.categories.save(productCategory)
    }
    if (typeof applicationBaseId === 'string') {
      const allBases = await window.sticky.applications.bases.getAll()
      const foundBase = allBases.find(b => b.id === applicationBaseId)
      const newApplicationPayload = await this.getNewApplicationPayloadFromBase(foundBase, { PRODUCT_ID: newProduct.id }, newProduct.name)
      await window.sticky.applications.create(newApplicationPayload)
    }
    window.parent.postMessage({ event: 'reloadProducts' })
    return newProduct
  }

  async createProduct (productCategory, customPayload = {}, applicationBaseId) {
    dispatch('LOADING')
    const newProduct = await this.doCreateProduct(productCategory, customPayload, applicationBaseId)
    const applications = await window.sticky.applications.getAll()
    const products = await window.sticky.products.getAll()
    this.setState({
      products,
      applications
    })
    dispatch('STOP_LOADING')
    dispatch('BLINK', { ids: [newProduct.id] })
  }

  async createProductCategory () {
    log('[Route-user] [createProductCategory]')
    dispatch('GET_INPUT', { why: 'productCategoryNew', hint: 'Choose a name:', string: 'Products', selectAll: true })
  }

  onProductCategoryEdit (productCategory) {
    dispatch('UNBLINK', { ids: [productCategory.id] })
    this.setState({
      currentProductCategory: productCategory
    })
  }

  async onMoveUpmost (entity, stateKey, saveMethod) {
    const entityIndex = this.state[stateKey].indexOf(entity)
    const firstEntity = this.state[stateKey][0]
    entity.patch({ createdAt: firstEntity.createdAt - 1 })
    this.setState({
      [stateKey]: [
        entity,
        ...this.state[stateKey].filter((e, i) => i !== entityIndex)
      ]
    })
    await saveMethod()
    scrollToTop(window)
  }
  async onMoveDownmost (entity, stateKey, saveMethod) {
    const entityIndex = this.state[stateKey].indexOf(entity)
    const lastEntity = this.state[stateKey][this.state[stateKey].length - 1]
    entity.patch({ createdAt: lastEntity.createdAt + 1 })
    this.setState({
      [stateKey]: [
        ...this.state[stateKey].filter((e, i) => i !== entityIndex),
        entity
      ]
    })
    await saveMethod()
    scrollToBottom(window)
  }

  async onThingAction (id, action) {
    const entity = this.state.things.find(p => p.id === id)
    log('[Route-user] [onThingAction]', { id, action, entity })
    const actions = {
      'rename': () => {
        dispatch('GET_INPUT', { why: 'thing', hint: 'Choose a new name:', entity, string: entity.name, selectAll: true })
      },
      'delete': async () => {
        dispatch('SURE_DELETE', { why: 'thing', entity, what: entity.name })
      },
      'move-upmost': async () => {
        await this.onMoveUpmost(
          entity,
          'things',
          () => window.sticky.things.save(entity, undefined, ['createdAt'])
        )
      }
    }
    await actions[action]()
    dispatch('UNBLINK', { ids: [id] })
  }

  async onApplicationAction (id, action) {
    const entity = this.state.applications.find(p => p.id === id)
    log('[Route-user] [onApplicationAction]', { id, action, entity })
    const actions = {
      'rename': () => {
        dispatch('GET_INPUT', { why: 'application', hint: 'Choose a new name:', entity, string: entity.name, selectAll: true })
      },
      'delete': async () => {
        dispatch('SURE_DELETE', { why: 'application', entity, what: entity.name })
      },
      'move-upmost': async () => {
        await this.onMoveUpmost(
          entity,
          'applications',
          () => window.sticky.applications.save(entity, ['createdAt'])
        )
      },
      'move-downmost': async () => {
        await this.onMoveDownmost(
          entity,
          'applications',
          () => window.sticky.applications.save(entity, ['createdAt'])
        )
      },
      'copy': async () => {
        const payload = {}
        ;[
          'name',
          'backendLogic',
          'frontendLogic',
          'primaryColor',
          'backgroundColor',
          'location',
          'events',
          'productCategories',

          'baseIcon',
          'baseSettingsRender',
          'stickypayApplePayEnabled',
          'stickypayGooglePayEnabled',
          'lightMode',
          'tags',
          'wellKnownIdentifier'
        ]
          .forEach(flap => {
            payload[flap] = entity[flap]
          })
        payload.socialMedia = entity.socialMedia.toString()
        payload.stickyretail = entity.stickyretail.toString()
        payload.sessionKeys = entity.sessionKeys.toJson()
        payload.stickypayFlags = entity.stickypayFlags.toJson()
        payload.externalGateways = entity.externalGateways.toArray()
        await this.doCreateApplication(payload)
      }
    }
    await actions[action]()
    dispatch('UNBLINK', { ids: [id] })
  }

  async onProductAction (action, product, productCategory) {
    log('[Route-user] [onProductAction]', { product, productCategory, action })
    const actions = {
      'getAll': this.refreshEverything,
      'copy': async () => {
        const payload = {}
        ;[
          'name',
          'media',
          'categories',
          'currency',
          'description',
          'isEnabled',
          'price',
          'questions',
          'subProducts'
        ]
          .forEach(flap => {
            payload[flap] = product[flap]
          })
        this.createProduct(productCategory, payload)
      },
      'delete': () => {
        dispatch('SURE_DELETE', { why: 'product', entity: product, what: product.name })
      }
    }
    await actions[action]()
    dispatch('UNBLINK')
  }

  async onProductCategoryAction (productCategory, action) {
    log('[Route-user] [onProductCategoryAction]', { productCategory, action })
    const actions = {
      'add': () => {
        this.createProduct(productCategory)
      },
      'delete': () => {
        dispatch('SURE_DELETE', { why: 'productCategory', entity: productCategory })
      },
      'sort': () => {
        this.refreshProductCategories()
      }
    }
    await actions[action]()
    dispatch('UNBLINK')
  }

  async refreshProductCategories () {
    const productCategories = await window.sticky.products.categories.getAll(true)
    this.setState({
      productCategories
    })
  }

  async refreshEverything () {
    const applications = await window.sticky.applications.getAll(true)
    const productCategories = await window.sticky.products.categories.getAll(true)
    const products = await window.sticky.products.getAll(true)
    this.setState({
      applications,
      productCategories,
      products
    })
  }

  async refreshThings (force = true) {
    const things = await window.sticky.things.getAll(force)
    this.setState({
      things
    })
  }

  async refreshApplications (force = true) {
    const applications = await window.sticky.applications.getAll(force)
    this.setState({
      applications
    })
  }

  async createFederatedUser () {
    const createdFu = await window.sticky.users.federated.create()
    const federatedUsers = await window.sticky.users.federated.getAll()
    this.setState(
      {
        federatedUsers
      },
      () => dispatch('BLINK', { ids: [createdFu.id] })
    ) 
  }

  async componentDidMount () {
    this.subscriptions = [
      subscribe('REDUX_HANDLER_READY', this.REDUX_HANDLER_READY)
    ]
    const applications = await window.sticky.applications.getAll()
    const applicationBaseSets = await window.sticky.applications.baseSets.getAll()
    const things = await window.sticky.things.getAll()
    const products = await window.sticky.products.getAll()
    const productIds = products.map(p => p.id)
    const productCategories = (await window.sticky.products.categories.getAll())
      .map(pc => {
        Array.from(pc.products)
          .filter(pid => !productIds.includes(pid))
          .forEach(pid => {
            pc.products.delete(pid)
          })
        return pc
      })
    const federatedUsers = await window.sticky.users.federated.getAll()

    const veryFreshState = { applications, applicationBaseSets, things, products, productCategories, federatedUsers }
    this.setState(veryFreshState)

    this.subscriptions = [
      ...this.subscriptions,
      subscribe('CHOOSE_APPLICATION_BASE_OR_BASE_SET_GOOD', this.CHOOSE_APPLICATION_BASE_OR_BASE_SET_GOOD),
      subscribe('NEW_BASE_PRODUCT_GOOD', this.NEW_BASE_PRODUCT_GOOD),
      subscribe('SURE_DELETE_GOOD', this.SURE_DELETE_GOOD),
      subscribe('GET_INPUT_GOOD', this.GET_INPUT_GOOD),
      subscribe('STOCK_GOOD', this.STOCK_GOOD),
      subscribe('PRODUCTS_IMPORT_GOOD', this.PRODUCTS_IMPORT_GOOD),
      subscribe('SHOW_MESSAGE_GOOD', this.SHOW_MESSAGE_GOOD)
    ]
  }

  componentWillUnmount () {
    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  render () {
    const {
      user
    } = this.props

    const headerBar = ({
      '/me/flows': {
        text: window.sticky._('APPLICATIONS'),
        Icon: dashboardIcons.application
      },
      '/me/stickies': {
        text: 'Stickies',
        Icon: dashboardIcons.thing
      },
      '/me/products': {
        text: 'Products',
        Icon: dashboardIcons.product
      },
      '/me/team': {
        text: 'Team',
        Icon: dashboardIcons.teamMembers
      }
    })[this.props.match.url]

    const {
      entity,
      subEntity
    } = this.props.match.params
    const {
      products,
      productCategories,
      currentProductCategory
    } = this.state
    const canRender = typeof user === 'object'
    if (!canRender) {
      return null
    }
    log('[Route-user] [render]', { productCategories: productCategories ? productCategories.map(pc => pc.name) : undefined, user, entity, subEntity })
    return (
      <StyledRoute>
        <CustomHelmet
          title={user.name}
        />
        {currentProductCategory && <ProductCategoryModal
          user={user}
          productCategory={currentProductCategory}
          products={products}
          onUpdate={(key, value) => {
            log('[Route-user] [render]->ProductCategoryModal->onUpdate', { key, value })
            currentProductCategory.patch({ [key]: value })
            this.forceUpdate()
          }}
          onSave={async () => {
            await window.sticky.products.categories.save(currentProductCategory)
            this.setState({ currentProductCategory: undefined })
            window.parent.postMessage({ event: 'reloadProducts' })
          }}
          onCancel={() => {
            this.setState({ currentProductCategory: undefined })
          }}
        />}
        <MainFrame
          user={user}
          autoUi={this.props.autoUi}
          aside={
            <>
              <User user={user} whichPart={this.props.match.path} autoUi={this.props.autoUi} />
            </>
          }
          main={<>
            {/* {notPaidPayment && (
              <Banner mood='very-bad'>
                <p>
                  You have not paid a bill due <Time time={notPaidPayment.dueDate} isByDay />.
                </p>
                <LinkButton sameTab to={`/me/payments-user-private-key/${notPaidPayment.id}`}>Pay it now →</LinkButton>
              </Banner>
            )} */}
            <HeaderBar {...headerBar} user={user} />
            <Box className='main-dish-1'>
              <TabBar
                selectedTab={entity}
                tabs={tabs
                  .filter(tab => tab.to(this))
                  .map(tab => ({
                    ...tab,
                    name: tab.name(this),
                    to: tab.to(this),
                    child: tab.child({ context: this })
                  }))}
              />
            </Box>
          </>}
        />
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  onUpdateUser: PropTypes.func,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
