// <H2>Text overrides</H2>
// <JsonEditor
// name='customData'
// json={user.customData.get()}
// includeKeys={[
// 'Custom name for "Cash"',
// 'Custom icon for "Cash"'
// ]}
// onChange={newJson => {
// user.customData.set(newJson)
// dbfProps.onChange('customData', user.customData)
// }}
// />

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isPrivateKey, EmailInput, Input, Switch, Button, NumberInput, Dropdown, Form, UrlInput, Label } from '@openbox-app-shared'
import Box from '../../components/Box'
import H2 from '../../components/H2'
import BankDetails from '../../components/BankDetails'
import KeyValuePairs from '../../components/KeyValuePairs'

import TagChooserUser from '../../components/TagChooserUser'
import dashboardIcons from '../../icons'

import _ from '../../_'
import { dispatch } from '../../redux'

const StyledView = styled.div`
  padding-top: 1rem;
  .tools {
    .component--h2 {
      margin-bottom 0.5rem;
    }
    * + .component--h2 {
      margin-top: 1rem;
    }
    .component--box > form > .component--button {
      display: block;
      width: auto;
    }
  }
  .signatures {
    .component--dropdown {
      margin-bottom: 1rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * {
        margin-top: 0 !important;
        max-width: none !important;
      }
    }
  }
  .component--box {
    .component--tags + .component--tags {
      margin-top: 0.5rem;
    }
    .component--dropdown {
      select {
        max-width: 192px;
      }
    }
    .component--input + .component--dropdown, .component--dropdown + .component--h2 {
      margin-top: 1rem;
    }
  }
  .component--box + .component--button {
    margin-top: 1rem;
  }
  .component--switch + .component--switch, .component--switch + .component--input.type--number {
    margin-top: 1rem;
  }
`

function RenderChild({ context, dbfProps }) {
  const { user, userPreferences } = context.props
  const [copyProductsStuff, setCopyProductsStuff] = useState({ privateKey: '' })
  const [copyApplicationsStuff, setCopyApplicationsStuff] = useState({ privateKey: '', isLoggedInAsPartner: user.isLoggedInAsPartner })

  function updatePreference(k, v) {
    userPreferences[k] = v
    dbfProps.onChange('userPreferences', userPreferences)
  }

  const onCopyProducts = () => {
    if (!isPrivateKey(copyProductsStuff.privateKey)) {
      return
    }
    dispatch('TRIGGER', { trigger: 'copy-products-all', body: copyProductsStuff })
  }
  const onCopyApplications = () => {
    if (!isPrivateKey(copyApplicationsStuff.privateKey)) {
      return
    }
    dispatch('TRIGGER', { trigger: 'copy-applications', body: copyApplicationsStuff })
  }

  const [stuff, setStuff] = useState()
  useEffect(
    () => {
      async function getStuff() {
        const newStuff = {
          things: await window.sticky.things.getAll()
        }
        setStuff(newStuff)
      }
      !stuff && getStuff()
    },
    []
  )

  if (!stuff) {
    return null
  }

  return (
    <StyledView>
      <div className='tools'>
        <div className='grid'>
          <Box>
            {user.federatedUserCan('applications') && <>
              <H2>Copy all flows</H2>
              <Form
                onSubmit={onCopyApplications}
                onChange={(k, v) => {
                  setCopyApplicationsStuff({
                    ...copyApplicationsStuff,
                    [k]: v
                  })
                }}
              >
                <Input
                  label='Destination private key'
                  name='privateKey'
                  value={copyApplicationsStuff.privateKey}
                  isValid={isPrivateKey(copyApplicationsStuff.privateKey)}
                />
                <Button onClick={onCopyApplications} disabled={!isPrivateKey(copyApplicationsStuff.privateKey)}>Copy now</Button>
              </Form>
            </>}
            <H2>Copy all products</H2>
            <Form
              onSubmit={onCopyProducts}
              onChange={(k, v) => {
                setCopyProductsStuff({
                  ...copyProductsStuff,
                  [k]: v
                })
              }}
            >
              <Input
                label='Destination private key'
                name='privateKey'
                value={copyProductsStuff.privateKey}
                isValid={isPrivateKey(copyProductsStuff.privateKey)}
              />
              <Switch
                name='copyFederatedUsers'
                checked={copyProductsStuff.copyFederatedUsers}
              >
                Include team members
              </Switch>
              <Button onClick={onCopyProducts} disabled={!isPrivateKey(copyProductsStuff.privateKey)}>Copy now</Button>
            </Form>
          </Box>
          {user.federatedUserCan('live-payments') && <Box>
            <H2>Live payments</H2>
            <Switch checked={[true, undefined].includes(userPreferences.toDoAssignPayments)} onChange={v => updatePreference('toDoAssignPayments', v)}>
              Payments can be assigned to team members
            </Switch>
            <Switch checked={[true, undefined].includes(userPreferences.toDoAccessCus)} onChange={v => updatePreference('toDoAccessCus', v)}>
              Team members can see consumer information and go to a payment
            </Switch>
            <Switch checked={[true, undefined].includes(userPreferences.toDoFilterByApplication)} onChange={v => updatePreference('toDoFilterByApplication', v)}>
              Filtering
            </Switch>
            <Switch checked={[true].includes(userPreferences.toDoShowOnlyUnpaidGATEWAY_LATER)} onChange={v => updatePreference('toDoShowOnlyUnpaidGATEWAY_LATER', v)}>
              Show "To pay" payment cards only
            </Switch>
            <Switch checked={[true].includes(userPreferences.toDoEVENT_TO_DOFirst)} onChange={v => updatePreference('toDoEVENT_TO_DOFirst', v)}>
              Show "To do" cards before payment cards
            </Switch>
            <Switch checked={[true, undefined].includes(userPreferences.toDoShowQuestionQuestions)} onChange={v => updatePreference('toDoShowQuestionQuestions', v)}>
              Show product question names ("Add acme? Yes" vs "Yes")
            </Switch>
            <NumberInput
              label='Payment "drop off" point (hours)'
              value={userPreferences.toDoOverrideLimitTime}
              min={1} // one hour
              max={24 * 90} // 90 days = approx 3 months; good luck api!
              onChange={v => {
                const isInteger = Number.isInteger(v) && v > 0
                isInteger && updatePreference('toDoOverrideLimitTime', v)
              }}
            />
            <Dropdown
              label='Sound'
              items={[
                { id: 'beep', name: 'Original' },
                { id: 'none', name: 'None' }
              ]}
              onChoose={v => updatePreference('toDoNewCardSound', v)}
              selected={userPreferences.toDoNewCardSound || 'beep'}
            />
          </Box>}
          <Box>
            <H2>{window.sticky._('STICKY_PAY')} / Take a payment</H2>
            <Switch checked={[true].includes(userPreferences.payByLinkRequired)} onChange={v => updatePreference('payByLinkRequired', v)}>
              Link → Your ID required
            </Switch>
            <Switch checked={[true, undefined].includes(userPreferences.posCashCard)} onChange={v => updatePreference('posCashCard', v)}>
              {window.sticky.Stickypay.GATEWAYS.find(_ => _.id === 'GATEWAY_CASH').name}
            </Switch>
          </Box>
          <Box>
            <H2>{window.sticky._('STICKY_PAY')}</H2>
            <Switch checked={[true].includes(userPreferences.posCashCardCard)} onChange={v => updatePreference('posCashCardCard', v)}>
              {window.sticky.Stickypay.GATEWAYS.find(_ => _.id === 'GATEWAY_CARD').name}
            </Switch>
          </Box>
          <Box>
            <H2>Tabs</H2>
            <Dropdown
              items={[
                { id: 'default', name: 'Default' },
                { id: 'airline', name: 'Airline' }
              ]}
              label='View'
              onChoose={v => updatePreference('thingsOverviewView', v)}
              selected={userPreferences.thingsOverviewView || 'default'}
            />
          </Box>
          <Box>
            <H2>Default payment labels</H2>
            <TagChooserUser
              user={user}
              tags={user.tagsPaymentsDefault}
              onUpdate={(id) => {
                user.tagsPaymentsDefault.toggle(id)
                dbfProps.onChange('tagsPaymentsDefault', user.tagsPaymentsDefault)
              }}
            />
          </Box>
          <Box>
            <H2>Invoice details</H2>
            <BankDetails
              bankDetails={user.bankDetails}
              onChange={bankDetails => dbfProps.onChange('bankDetails', bankDetails)}
              onSave={context.props.onSaveUser}
            />
          </Box>
          <Box>
            <H2>Payment email templates</H2>
            <Form>
              <EmailInput
                disabled
                label='From'
                value={user.destinationEmailFrom}
              />
              <Input
                disabled
                label='Subject'
                value={user.destinationEmailSubject}
              />
              <Input
                disabled
                label='Body'
                type='textarea'
                value={user.destinationEmailBody}
              />
            </Form>
          </Box>
        </div>
      </div>
    </StyledView>
  )
}
RenderChild.propTypes = {
  context: PropTypes.object,
  dbfProps: PropTypes.object
}

export default {
  id: 'more',
  name: (context) => '',
  inlineIcon: dashboardIcons.dotDotDot,
  to: context => '/my/account/more',
  child: props => <RenderChild {...props} />
}
