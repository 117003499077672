import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { sessionKeysAndHowToValidateThemByKey } from '../sessionKeysAndHowToValidateThem'
import T from './T'

import { Input, DynamicInput } from './Input'
import ApplicationBlocks from './ApplicationBlocks'
import _ from '../_'

const StyledSessionCrossUserSector = styled.div`
  > * + * {
    margin-top: 1rem;
  }
`

const values = [
  [
    'name',
    {
      value: ({ legacyReadFrom }) => legacyReadFrom['name'],
      isValid: ({ legacyReadFrom, extraCrud }) => sessionKeysAndHowToValidateThemByKey.name.isValid(legacyReadFrom['name'], extraCrud)
    }
  ],
  [
    'companyName',
    {
      value: ({ legacyReadFrom }) => legacyReadFrom['companyName'],
      isValid: ({ legacyReadFrom, extraCrud }) => sessionKeysAndHowToValidateThemByKey.companyName.isValid(legacyReadFrom['companyName'], extraCrud)
    }
  ],
  [
    'email',
    {
      value: ({ legacyReadFrom }) => legacyReadFrom['email'],
      isValid: ({ legacyReadFrom, extraCrud }) => sessionKeysAndHowToValidateThemByKey.email.isValid(legacyReadFrom['email'], extraCrud)
    }
  ],
  [
    'phone',
    {
      value: ({ legacyReadFrom }) => legacyReadFrom['phone'],
      isValid: ({ legacyReadFrom, extraCrud }) => sessionKeysAndHowToValidateThemByKey.phone.isValid(legacyReadFrom['phone'], extraCrud)
    }
  ],
  [
    'address',
    {
      value: ({ legacyReadFrom }) => legacyReadFrom['address'],
      isValid: ({ legacyReadFrom, extraCrud }) => sessionKeysAndHowToValidateThemByKey.address.isValid(legacyReadFrom['address'], extraCrud)
    }
  ],
  [
    'extra',
    {
      value: ({ legacyReadFrom }) => {
        return legacyReadFrom['extra']
      }
    }
  ]
]

function getSkCollectionTimeConfig (application) {
  const maybeThis = application.stickyretail.readFrom('skCollectionTimeConfig')
  return maybeThis ? JSON.parse(maybeThis) : {
    ...sessionKeysAndHowToValidateThemByKey.collectionTime.defaultValue,
    label: sessionKeysAndHowToValidateThemByKey.collectionTime.defaultValue.label || _(sessionKeysAndHowToValidateThemByKey.collectionTime.textT, window.stickySessionLanguage && window.stickySessionLanguage[0])
  }
}

export default function SessionCrossUserSector ({
  application,
  sessionKeysToRender,
  sessionCusCommonProps = {},
  legacyReadFrom,
  extraCrud,
  doPrePayOnChange
}) {
  let [skCollectionTimeConfig, setSkCollectionTimeConfig] = useState(getSkCollectionTimeConfig(application))
  useEffect(
    () => {
      if (['collectionTime'].some(_ => sessionKeysToRender.includes(_))) {
        const renderTimeout = setTimeout(
          () => {
            window.sticky.applications.get(undefined, true, 'SessionCrossUserSector->getApplication')
              .then(a => {
                const newConfig = getSkCollectionTimeConfig(a)
                setSkCollectionTimeConfig(newConfig)
              })
              .catch(_ => {})
          },
          10 * 1000
        )
        return () => {
          clearTimeout(renderTimeout)
        }
      }
    }
  )

  const [[, vName], [, vCompanyName], [, vEmail], [, vPhone], [, vAddress], [, vExtra]] = values

  let whichToAutoFocus = values.find(v => {
    const [vName, vObject] = v
    if (!sessionKeysToRender.includes(vName)) {
      return false
    }
    const { isValid } = vObject
    if (typeof isValid !== 'function') {
      return false
    }
    return !isValid({ legacyReadFrom, extraCrud })
  })
  console.log('[SessionCrossUserSector]', { sessionKeysToRender, whichToAutoFocus, vAddress: vAddress.value({ legacyReadFrom }) })

  const handleOnReady = (name, { autoFocus }) => {
  }

  const doShowAddress = sessionKeysToRender.includes('address')
  const doShowCollection = sessionKeysToRender.includes('collectionTime')

  return <StyledSessionCrossUserSector>
    {sessionKeysToRender.includes('name') && <Input {...sessionCusCommonProps}
      autocomplete='given-name'
      value={vName.value({ legacyReadFrom })}
      isValid={vName.isValid({ legacyReadFrom, extraCrud})}
      label={<T>{sessionKeysAndHowToValidateThemByKey.name.textT}</T>}
      onChange={(value) => doPrePayOnChange('name', value)}
      onReady={(...args) => handleOnReady('name', ...args)}
    />}
    {sessionKeysToRender.includes('companyName') && <Input {...sessionCusCommonProps}
      autocomplete='organization'
      value={vCompanyName.value({ legacyReadFrom })}
      isValid={vCompanyName.isValid({ legacyReadFrom, extraCrud})}
      label={<T>{sessionKeysAndHowToValidateThemByKey.companyName.textT}</T>}
      onChange={(value) => doPrePayOnChange('companyName', value)}
      onReady={(...args) => handleOnReady('companyName', ...args)}
    />}
    {sessionKeysToRender.includes('email') && <DynamicInput type='email' {...sessionCusCommonProps}
      value={vEmail.value({ legacyReadFrom })}
      label={<T>{sessionKeysAndHowToValidateThemByKey.email.textT}</T>}
      fireIfInvalid
      onChange={(value) => doPrePayOnChange('email', value)}
      onReady={(...args) => handleOnReady('email', ...args)}
    />}
    {sessionKeysToRender.includes('phone') && <DynamicInput type='phone' {...sessionCusCommonProps}
      value={vPhone.value({ legacyReadFrom })}
      label={<T>{sessionKeysAndHowToValidateThemByKey.phone.textT}</T>}
      fireIfInvalid
      onChange={(value) => doPrePayOnChange('phone', value)}
      onReady={(...args) => handleOnReady('phone', ...args)}
    />}
    {doShowCollection && <ApplicationBlocks
      applicationBlocks={[
        {
          id: 'form--time-slot',
          config: skCollectionTimeConfig
        }
      ]}
    />}
    {doShowAddress && <Input {...sessionCusCommonProps}
      type='textarea'
      autocomplete='street-address'
      value={vAddress.value({ legacyReadFrom })}
      isValid={vAddress.isValid({ legacyReadFrom, extraCrud})}
      label={<T>{sessionKeysAndHowToValidateThemByKey.address.textT}</T>}
      onChange={(value) => doPrePayOnChange('address', value)}
      onReady={(...args) => handleOnReady('address', ...args)}
    />}
    {sessionKeysToRender.includes('extra') && <Input {...sessionCusCommonProps}
      type='textarea'
      value={vExtra.value({ legacyReadFrom })}
      label={<T>{sessionKeysAndHowToValidateThemByKey.extra.textT}</T>}
      onChange={(value) => doPrePayOnChange('extra', value)}
      onReady={(...args) => handleOnReady('extra', ...args)}
    />}
  </StyledSessionCrossUserSector>
}

SessionCrossUserSector.propTypes = {
  application: PropTypes.object,
  sessionKeysToRender: PropTypes.arrayOf(PropTypes.string),
  sessionCusCommonProps: PropTypes.object,
  legacyReadFrom: PropTypes.object,
  extraCrud: PropTypes.object,
  doPrePayOnChange: PropTypes.func
}
