/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { icons, tags, CustomHelmet, Loading, Modal, Button } from '@openbox-app-shared'
import Payment from './payment'
import ModalYes from '../../../components/modals/Yes'

import { log, error } from '../../../log'
import beep from '../../../beep'
import { dispatch } from '../../../redux'

const DO_REFRESH = true
const REFRESH_INTERVAL = 10 * 1000

const StyledRoute = styled.div`
  padding: 1rem;
  .payments {
    .component--payment {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  .beep-yes-no {
    p {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    .buttons {
      text-align: center;
      > * {
        display: inline-block;
        vertical-align: top;
      }
      > * + * {
        margin-left: 1rem;
      }
    }
  }
`

function doesMatch (payment, federatedUser) {
  if (typeof payment.lastFederatedUserId === 'string' && payment.lastFederatedUserId !== federatedUser.id) {
    return false
  }
  const fuTags = tags.filter(t => t.doesAFederatedUserCare).map(t => t.id)
  const toReturn = payment.cart.get().every(ci => {
    return Array.from(ci.product.categories).filter(t => fuTags.includes(t)).every(t => federatedUser.categories.has(t))
  })
  log('[Route-view-stickyretailSimple] [doesMatch]', { payment, federatedUser, fuTags, toReturn })
  return toReturn
}

export default class Route extends Component {
  constructor () {
    super()
    this.state = {
      allPaymentsLength: undefined,
      showingDummyModal: true
    }
    this.isGettingPayments = false
  }

  newPayment () {
    this.beeper.beep()
  }

  async getAllPayments (federatedUser) {
    const parts = ['newStatusDone=false', 'sessionPaidAt=any']
    window.sticky.pay.setQuery(parts.join('&'))
    const allPayments = [...(await window.sticky.pay.getAll(true))]
      .reverse()
      .filter(p => {
        return !federatedUser ? true : doesMatch(p, federatedUser)
      })
    log('[Route-view-stickyretailSimple] [getAllPayments]', { allPayments })
    return allPayments
  }

  async getPayments () {
    const onDone = () => {
      this.isGettingPayments = false
    }
    if (this.isGettingPayments) {
      return
    }
    this.isGettingPayments = true

    const things = await window.sticky.things.getAll()
    const { federatedUserId } = this.props.match.params
    const federatedUser = await window.sticky.users.federated.get(federatedUserId)
    if (federatedUser) {
      document.title = federatedUser.name
    }

    log('[Route-view-stickyretailSimple] [getPayments]', { federatedUserId, federatedUser, isGettingPayments: this.isGettingPayments })

    try {
      const allPayments = await this.getAllPayments(federatedUser)
      const { allPaymentsLength } = this.state
      const isThereANewPayment = typeof allPaymentsLength === 'number' && allPayments.length > allPaymentsLength
      if (isThereANewPayment) {
        this.newPayment()
      }
      this.setState(
        {
          payments: allPayments,
          things,
          allPaymentsLength: allPayments.length,
          federatedUser,
          noMansLand: false
        },
        onDone
      )
    } catch (e) {
    } finally {
      onDone()
    }
  }

  async componentDidMount () {
    const { user, userPreferences } = this.props
    this.beeper = beep(userPreferences.toDoNewCardSound || 'beep')

    this.backupState = {
      backgroundColor: document.body.style.backgroundColor,
      backgroundImage: document.body.style.backgroundImage,
      navbar: document.querySelector('.component--navbar').style.maxWidth
    }
    document.body.style.backgroundColor = 'black'
    document.body.style.backgroundImage = 'none'
    document.querySelector('.component--navbar').style.display = 'none'

    if (!user.can('show-linear-operator-view')) {
      window.location = `/me/flows?userPrivateKey=${user.privateKey}&userPublicKey=${user.publicKey}`
      return
    }

    await this.getPayments()

    log('[Route-view-stickyretailSimple] [componentDidMount] (1)')
    const onMessage = data => {
      log('[Route-view-stickyretailSimple] [componentDidMount] [onMessage]', { data })
      data.status === 'bus-v2-payment-success' && this.getPayments()
      data.type === 'payment-accepted-1' && (() => {
        const currentPayment = this.state.payments[this.state.payments.length - 1]
        log('[Route-view-stickyretailSimple] [componentDidMount] [onMessage] -> payment-accepted-1', data.paymentId, currentPayment)
        if (currentPayment && currentPayment.id === data.paymentId && this.state.federatedUser.id !== data.federatedUserId) {
          log('[Route-view-stickyretailSimple] [componentDidMount] [onMessage] -> payment-accepted-1 ENTER NML')
          this.setState({
            noMansLand: true
          })
        }
      })()
      data.type === 'payment-accepted-2' && (() => {
        const currentPayment = this.state.payments[this.state.payments.length - 1]
        log('[Route-view-stickyretailSimple] [componentDidMount] [onMessage] -> payment-accepted-2', data.paymentId, currentPayment)
        if (currentPayment && currentPayment.id === data.paymentId && this.state.federatedUser.id !== data.federatedUserId) {
          log('[Route-view-stickyretailSimple] [componentDidMount] [onMessage] -> payment-accepted-2 EXIT NML')
          this.getPayments()
        }
      })()
    }
    await new Promise((resolve, reject) => {
      window.sticky.bus.connect(user.lightMode, `${user.publicKey}---generic`, { onMessage })
        .then(({ free, message }) => {
          log('[Route-view-stickyretailSimple] [componentDidMount] (2) bus connection succeeded', { free, message })
          message({ status: 'bus-v2-engaged', userId: user.id })
          this.setState(
            {
              busObject: {
                free,
                message
              }
            },
            resolve
          )
        })
        .catch(e => {
          error('[Route-view-stickyretailSimple] [componentDidMount] (3) bus connection failed; ignoring', e)
          resolve()
        })
    })
    this.refreshTimer = DO_REFRESH && setInterval(
      () => {
        this.getPayments()
      },
      REFRESH_INTERVAL
    )
    if (window.sticky.isAppleWatch()) {
      document.querySelector('.version').style.display = 'none'
    }
  }

  async componentWillUnmount () {
    this.beeper.unbeep()
    this.refreshTimer && clearInterval(this.refreshTimer)
    window.sticky.bodgeZone.busObject && busObject.free()
    document.body.style.backgroundColor = this.backupState.backgroundColor
    document.body.style.backgroundImage = this.backupState.backgroundImage
    document.querySelector('.component--navbar').style.display = this.backupState.navbar
  }

  render () {
    const { userPreferences } = this.props
    const {
      payments,
      things,
      federatedUser,
      showingDummyModal,
      noMansLand
    } = this.state
    log('[Route-view-stickyretailSimple] [render]', { payments, noMansLand })

    const paymentMaps = [payments, things].every(_ => _) ? payments : []
    const p = paymentMaps.length > 0 && paymentMaps[paymentMaps.length - 1]
    const thing = p && things.find(t => t.id === p.thingId)

    return (
      <StyledRoute>
        <CustomHelmet
          title='Linear live payments'
        />
        {showingDummyModal && (
          <Modal
            className='beep-yes-no'
            canBeClosed={false}
          >
            <p>Beep when there's a new card?</p>
            <div className='buttons'>
              <Button
                icon={icons.inverted.check}
                onClick={() => {
                  this.beeper.start()
                  this.setState({
                    showingDummyModal: false
                  })
                }}
              >
                Beep!
              </Button>
              <Button
                isSecondary
                onClick={() => {
                  this.setState({
                    showingDummyModal: false
                  })
                }}
              >
                No
              </Button>
            </div>
          </Modal>
        )}
        {(!paymentMaps || noMansLand) && <Loading />}
        {p && !noMansLand && <div className='payments'>
          {!showingDummyModal && federatedUser && p.lastFederatedUserId !== federatedUser.id && <ModalYes
            onGood={async () => {
              window.sticky.bodgeZone.busObject && window.sticky.bodgeZone.busObject.message({ type: 'payment-accepted-1', paymentId: p.id, federatedUserId: federatedUser.id })
              p.lastFederatedUserId = federatedUser.id
              this.forceUpdate()
              dispatch('LOADING')
              await window.sticky.pay.save(p, false)
              window.sticky.bodgeZone.busObject && window.sticky.bodgeZone.busObject.message({ type: 'payment-accepted-2', paymentId: p.id, federatedUserId: federatedUser.id })
              dispatch('STOP_LOADING')
            }}
          />}
          <Payment
            key={p.id}
            userPreferences={userPreferences}
            currentFederatedUser={federatedUser}
            payment={p}
            thing={thing}
            onDone={async () => {
              p.newStatusDone = true
              dispatch('LOADING')
              await window.sticky.pay.save(p, false)
              await this.getPayments()
              dispatch('STOP_LOADING')
            }}
          />
        </div>}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  match: PropTypes.object
}
