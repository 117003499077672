/* eslint-disable react/prop-types */
import React from 'react'
import { icons } from '@openbox-app-shared'
import { Price } from '@openbox-app-shared'
import dashboardIcons from '../../icons'

const EVENT_TYPES = [
  ['SESSION_CART_PAY', { shouldBeInSummary: true, color: '#26c281', text: () => <strong>Paid</strong>, inlineIcon: dashboardIcons.payment }],
  ['SESSION_CART_PAY_FAIL', { shouldBeInSummary: true, color: '#ff3838', text: () => <strong>Payment failed</strong>, inlineIcon: dashboardIcons.payment }],
  ['LD_V2', {
    shouldBeInSummary: true,
    inlineIcon: dashboardIcons.check,
    color: '#6c5ce7',
    text: ({ event }) => <strong>Form {event.consumerIdentifierId ? ` (${event.consumerIdentifierId})` : ''}</strong>,
    toDetails: (user, id, customData, linearData) => {
      return Object.keys(customData.get()).map(cdk => ([cdk, customData.readFrom(cdk)]))
    }
  }],
  ['CHECK_IN', { shouldBeInSummary: true, color: '#fb417b', text: () => <strong>Check in</strong>, inlineIcon: dashboardIcons.location }],
  ['CHECK_OUT', { shouldBeInSummary: true, color: '#d90547', text: () => <strong>Check out</strong>, inlineIcon: dashboardIcons.location }],
  [ 'TOUCHPOINT_1', { color: '#ff5c87', text: ({ user, event }) => <strong>{event.customData.readFrom('Label') || 'Touchpoint 1'}</strong>, inlineIcon: dashboardIcons.location } ],
  [ 'TOUCHPOINT_2', { color: '#ff7194', text: ({ user, event }) => <strong>{event.customData.readFrom('Label') || 'Touchpoint 2'}</strong>, inlineIcon: dashboardIcons.location } ],
  [ 'TOUCHPOINT_3', { color: '#ff85a1', text: ({ user, event }) => <strong>{event.customData.readFrom('Label') || 'Touchpoint 3'}</strong>, inlineIcon: dashboardIcons.location } ],
  [ 'TOUCHPOINT_4', { color: '#ff98ae', text: ({ user, event }) => <strong>{event.customData.readFrom('Label') || 'Touchpoint 4'}</strong>, inlineIcon: dashboardIcons.location } ],
  [ 'TOUCHPOINT_5', { color: '#ffaabb', text: ({ user, event }) => <strong>{event.customData.readFrom('Label') || 'Touchpoint 5'}</strong>, inlineIcon: dashboardIcons.location } ],
  [
    'DESTINATION',
    {
      text: ({ event }) => <strong style={{ color: '#68df90'}}>Outbound {event.customData && <>({event.customData.readFrom('type')})</>}</strong>,
      toDetails: (user, id, customData) => {
        return [
          ['Destination', customData.readFrom('destination')],
          customData.readFrom('message') && ['Message', customData.readFrom('message')]
        ]
          .filter(_ => _)
      },
    }
  ],
  [
    'PAYMENTS_REPORT_CAPTURE',
    {
      color: '#000000',
      text: () => <strong>End of day</strong>,
      inlineIcon: dashboardIcons.payment,
      showSessionId: false,
      toDetails: (user, id, customData) => {
        return [
          // ['Report', <span key='d-xyz' style={{ whiteSpace: 'pre' }}>{JSON.stringify(customData.readFrom('data'), null, 2)}</span>],
          ['Total', <span key='d-xyz'><Price price={customData.readFrom('data').totalTotal} currency={customData.readFrom('data').currency} /></span>],
          ...Object.keys(customData.readFrom('data').gateway).map(k => ([window.sticky.Stickypay.GATEWAYS.find(_ => _.id === k).name, <Price className='indent-me' key={`d--${k}`} price={customData.readFrom('data').gateway[k]} currency={customData.readFrom('data').currency} />])),
          ['Refunds', <span key='d-xyz'><Price price={customData.readFrom('data').totalRefunded} currency={customData.readFrom('data').currency} /></span>],
          ['You get', <span key='d-xyz'><Price price={customData.readFrom('data').totalTheyGet} currency={customData.readFrom('data').currency} /></span>],
          ['Discounts', <span key='d-xyz'><Price price={customData.readFrom('data').totalDiscount} currency={customData.readFrom('data').currency} /></span>],
          ['Service', <span key='d-xyz'><Price price={customData.readFrom('data').totalTip} currency={customData.readFrom('data').currency} /></span>],
          ['Comp', <span key='d-xyz'><Price price={customData.readFrom('data').totalComp} currency={customData.readFrom('data').currency} /></span>]
        ]
      },
    }
  ],
  [
    'CHP_END_OF_DAY',
    {
      color: '#000000',
      text: () => <strong>Sticky card machine "End of day"</strong>,
      inlineIcon: dashboardIcons.payment,
      toDetails: (user, id, customData) => {
        return [
          ['Report', <span key='d-xyz' style={{ whiteSpace: 'pre' }}>{customData.readFrom('data')}</span>]
        ]
      },
    }
  ],

  ['VOID_PAYMENT', { color: '#ff3838', shouldBeInSummary: true, text: () => <strong>Voided payment</strong>, inlineIcon: dashboardIcons.void }],
  ['VOID_PRODUCT', { color: '#ff3838', text: () => <strong>Voided product</strong>, inlineIcon: dashboardIcons.void }],

  [
    'COMP_PAYMENT',
    {
      shouldBeInSummary: true,
      color: '#f7b731',
      text: ({ event }) => {
        return (
          event.customData ?
            <strong style={{ color: '#f7b731'}}>Comp'd {event.customData.readFrom('compdAmount')} ({event.customData.readFrom('compdName')})</strong>
            :
            <strong style={{ color: '#f7b731'}}>Comp'd</strong>
        )
      },
      toDetails: (user, id, customData) => {
        return [
          ['Before', `price:${customData.readFrom('totalBefore')}/${customData.readFrom('currency')}`],
          ['After', `price:${customData.readFrom('totalAfter')}/${customData.readFrom('currency')}`]
        ]
      },
      inlineIcon: dashboardIcons.void
    }
  ],

  ['SESSION_CART_REFUND', { shouldBeInSummary: true, color: '#ff3838', text: () => <strong>Refunded</strong>, inlineIcon: dashboardIcons.goBack }],
  [
    'SESSION_CART_DISCOUNT',
    {
      shouldBeInSummary: true,
      text: ({ event }) => {
        return (
          event.customData ?
            <strong style={{ color: '#3498db'}}>Discounted <Price price={event.customData.readFrom('discount')} currency={event.customData.readFrom('currency')} /></strong>
            :
            <strong style={{ color: '#3498db'}}>Discounted</strong>
        )
      },
      icon: () => icons.generic.discount
    }
  ],

  ['SESSION_READ', { shouldBeInSummary: true, color: '#6c5ce7', text: () => <strong>Interacted</strong>, inlineIcon: dashboardIcons.thing }],
  [
    'TO_DO',
    {
      shouldBeInSummary: true, color: '#322CBE',
      text: ({ event }) => {
        return (
          <strong>
            {event.customData && <>To do: {event.customData.readFrom('what')}</>}
            {!event.customData && <>To do</>}
          </strong>
        )
      },
      inlineIcon: dashboardIcons.check
    }
  ],
  [
    'ITEM',
    {
      shouldBeInSummary: true, color: '#322CBE',
      text: ({ event }) => {
        return (
          <strong>
            {event.customData && <>Given × {event.customData.readFrom('quantity')} {event.customData.readFrom('name')}</>}
            {!event.customData && <>Given item</>}
          </strong>
        )
      },
      icon: ({ event }) => event.customData ? event.customData.readFrom('url') : `${window.sticky.cdn}/items/item--star.png`
    }
  ],
  [
    'ITEM_DELETE',
    {
      color: '#322CBE',
      text: ({ event }) => {
        return (
          <strong>
            {event.customData && <>Taken × {event.customData.readFrom('quantity')} {event.customData.readFrom('name')}</>}
            {!event.customData && <>Taken item</>}
          </strong>
        )
      },
      icon: () => `${window.sticky.cdn}/items/item--star.png`
    }
  ],
  [
    'CONNECTION_GOOD',
    {
      text: ({ user, event }) => {
        const foundConnection = user.getConnection(event.customData.readFrom('id'))
        return foundConnection ? <strong><span style={{ color: foundConnection.color }}>{foundConnection.name}</span><span style={{ color: '#40cf7a' }}> — Success</span></strong> : <strong>??? — Success</strong>
      },
      icon: ({ user, event }) => {
        const foundConnection = user.getConnection(event.customData.readFrom('id'))
        return foundConnection ? foundConnection.logo : undefined
      },
      toDetails: (user, id, customData) => {
        return [
          ['ID', customData.readFrom('theirId')]
        ]
      }
    }
  ],
  [
    'CONNECTION_BAD',
    {
      text: ({ user, event }) => {
        const foundConnection = user.getConnection(event.customData.readFrom('id'))
        return foundConnection ? <strong><span style={{ color: foundConnection.color }}>{foundConnection.name}</span><span style={{ color: '#ff3838' }}> — Failure</span></strong> : <strong>??? — Failure</strong>
      },
      icon: ({ user, event }) => {
        const foundConnection = user.getConnection(event.customData.readFrom('id'))
        return foundConnection ? foundConnection.logo : undefined
      },
      toDetails: (user, id, customData) => {
        return [
          ['Message', customData.readFrom('message')]
        ]
      }
    }
  ],
  [
    'WEBHOOK_FAIL',
    {
      text: ({ user, event }) => {
        return <strong>Webhook<span style={{ color: '#ff3838' }}> — Failure</span></strong>
      },
      toDetails: (user, id, customData) => {
        return [
          ['Webhook method', customData.readFrom('webhookType')],
          ['Webhook URL', customData.readFrom('webhookUrl')],
          ['Attempt number', customData.readFrom('attemptIndex')],
          ['Error message', customData.readFrom('errorMessage')],
          ['Activity type', customData.readFrom('eventType')]
        ]
      }
    }
  ],

  ['LD_V1_CHECKLIST', {
    text: () => <strong style={{ color: '#26c281'}}>Checklist completed</strong>,
    toDetails: (user, id, customData, linearData) => {
      return [
        ['Items', <ul className='plain' key='d--ul'>{linearData.map(l => (<li key={l}>{l}</li>))}</ul>],
      ]
    }
  }],
  ['LD_V1_FEEDBACK', {
    text: () => <strong style={{ color: '#6c5ce7'}}>Feedback</strong>,
    toDetails: (user, id, customData, linearData) => {
      return [
        [customData.readFrom('question'), linearData[0]]
      ]
    }
  }],
  ['THING_CREATE', { color: '#6C7A89', text: () => <strong>Created</strong>, inlineIcon: dashboardIcons.thing }],
  ['THING_UPDATE', { color: '#6C7A89', text: () => <strong>Updated</strong>, inlineIcon: dashboardIcons.thing }]
  // ['VERIFY_VERIFY', { text: () => <strong style={{ color: '#40CF7A'}}>Verified</strong>, icon: () => icons.generic.verify }],
  // ['VERIFY_UNVERIFY', { text: () => <strong style={{ color: '#FF3838'}}>Unverified</strong>, icon: () => icons.generic.unverify }],
  // ['SESSION_CART_PAY_ITEM', { color: '#26c281', text: () => <strong>Paid for an item</strong>, inlineIcon: dashboardIcons.payment }],
  // ['SESSION_CART_CLEAR', { text: () => <strong style={{ color: '#6C7A89'}}>Cart cleared</strong> }],
  // ['SESSION_CART_ADD_ITEM', { text: () => <strong style={{ color: '#6C7A89'}}>Added to bag</strong> }],
  // ['SESSION_CART_REMOVE_ITEM', { text: () => <strong style={{ color: '#6C7A89'}}>Removed from bag</strong> }],
  // ['APPLICATION_CREATE', { color: '#6C7A89', text: () => <strong>Created</strong>, inlineIcon: dashboardIcons.application }],
  // ['PRODUCT_CREATE', { color: '#6C7A89', text: () => <strong>Created</strong>, inlineIcon: dashboardIcons.product }],
  // ['GATEWAY_UPDATE', { inlineIcon: dashboardIcons.payment, color: '#6C7A89', text: () => <strong>Payment provider updated</strong> }],
  // ['USER_UPDATE', { text: () => <strong style={{ color: '#6C7A89'}}>Dashboard updated</strong> }],
  // ['APPLICATION_UPDATE', { color: '#6c5ce7', text: () => <strong>Updated</strong>, inlineIcon: dashboardIcons.application }],
  // ['PRODUCT_UPDATE', { color: '#6c5ce7', text: () => <strong>Updated</strong>, inlineIcon: dashboardIcons.product }]
]

export default EVENT_TYPES
