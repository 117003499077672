import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const StyledBar = styled.nav`
  background-color: white;
  &.will-render {
    > div ul {
      border-bottom: 2px solid #F0F1F3;
    }
  }
  > div {
    height: 100%;
    margin: 0 auto 0 auto;
    max-width: 1192px;
    ul {
      list-style-type: none;
      li {
        display: inline-block;
        margin-right: 0.75rem;
        margin-bottom: 0.5rem;
        > * > * + .name {
          margin-left: 0.6rem;
        }
        > * {
          display: block;
          height: 3rem;
          padding-left: 0.75rem;
          padding-right: 0.75rem;
          line-height: 3rem;
          text-decoration: none;
          transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
          border-radius: 6px;
          border: 2px solid transparent;
          outline: 0;
          img, svg {
            display: inline-block;
          }
          strong {
            vertical-align: 0.15rem;
          }
          img {
            height: 1.25rem;
            vertical-align: -0.15rem;
          }
          svg {
            width: 1.25rem;
            height: 1.25rem;
            vertical-align: -0.25rem;
          }
        }
        > *:not(a) {
          cursor: default;
        }
        > *:not(.active) {
          color: #1A1F35;
        }
        > *.active {
          color: #1A1F35;
          border-color: #1A1F35;
        }
        > *:not(.active):focus {
          box-shadow: 0 2px 4px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2), #cccbf0 0px 0px 0px 3px;
        }
      }
      li:first-child > * {
        margin-left: 0;
      }
      li:last-child > * {
        margin-right: 0;
      }
      li.big-icon {
        > * > img, > * > svg {
          height: 1.6rem;
          vertical-align: -0.35rem;
        }
      }
    }
  }
`

function Bar ({ className, children }) {
  const willRender = children.some(c => c)
  return (
    <StyledBar className={classnames('component--bar print-hide', { 'will-render': willRender }, className)}>
      <div>
        <ul>
          {children}
        </ul>
      </div>
    </StyledBar>
  )
}

Bar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default Bar
