import React from 'react'
import Price from './components/Price'

function getPrepositionFrom (product) {
  // where...
  return [
    // at least one product question has an option > 0
    product.questions
      .some(q => q.options.some(o => o.delta > 0)),
    // AND every product question option is >= 0 (no negatives)
    product.questions
      .every(q => q.options.every(o => o.delta >= 0))
  ]
    .every(e => e)
}

const MODES = {
  'SK_name': new Map([
    ['default', () => 'Name'],
    ['DEU', () => 'Name'],
    ['SPA', () => 'Nombre']
  ]),
  'SK_companyName': new Map([
    ['default', () => 'Company name'],
    ['DEU', () => 'Firma'],
    ['SPA', () => 'Nombre de la empresa']
  ]),
  'SK_email': new Map([
    ['default', () => 'Email'],
  ]),
  'SK_phone': new Map([
    ['default', () => 'Phone number'],
    ['DEU', () => 'Handynummer'],
    ['SPA', () => 'Número de móvil']
  ]),
  'SK_address': new Map([
    ['default', () => 'Address'],
    ['DEU', () => 'Adresse'],
    ['SPA', () => 'Dirección']
  ]),
  'SK_collectionTime': new Map([
    ['default', () => 'Collection time'],
    ['DEU', () => 'Collection time'],
    ['SPA', () => 'Horario de recogida']
  ]),
  'SK_extra': new Map([
    ['default', () => 'Special requirements'],
    ['SPA', () => '¿Algún comentario o sugerencia?']
  ]),
  'SK_userPrivateKey': new Map([
    ['default', () => 'Private key'],
    ['DEU', () => 'Privater Schlüssel'],
    ['SPA', () => 'Clave privada']
  ]),
  // 'SK_distributionMode': new Map([
  //   ['default', () => 'Collection or delivery?'],
  //   ['DEU', () => 'Abholung oder Lieferung?'],
  //   ['SPA', () => '¿Recogida o entrega?']
  // ]),
  // 'POWERED_BY': new Map([
  //   ['default', () => 'We run on Sticky®'],
  //   ['DEU', () => 'Diese App läuft auf Sticky®']
  // ]),
  // 'HOW_TO_PAY': new Map([
  //   ['default', () => 'How do you want to pay?'],
  //   ['DEU', () => 'Wie möchtest du bezahlen?'],
  //   ['SPA', () => '¿Cómo le gustaría pagar?']
  // ]),
  'ON_MY_PHONE': new Map([
    ['default', () => 'Pay on my phone'],
    ['DEU', () => 'Mit meinem Handy bezahlen'],
    ['SPA', () => 'Pagar con el móvil']
  ]),
  'GATEWAY_KINETIC': new Map([
    ['default', () => 'Use a card machine'],
    ['DEU', () => 'Jetzt Kartenautomat nutzen'],
    ['SPA', () => 'Usar máquina de tarjetas']
  ]),
  'GATEWAY_PAY_BY_THING': new Map([
    ['default', () => 'Tap sticky'],
    ['DEU', () => 'Jetzt Aufkleber antippen']
  ]),
  'GATEWAY_CASH': new Map([
    ['default', () => 'Pay in cash'],
    ['DEU', () => 'Jetzt bar bezahlen'],
    ['SPA', () => 'Pagar en efectivo ahora']
  ]),
  'GATEWAY_CARD': new Map([
    ['default', () => 'Use a card machine'],
    ['DEU', () => 'Jetzt Kartenautomat nutzen'],
    ['SPA', () => 'Usar máquina de tarjetas']
  ]),
  'GATEWAY_LATER': new Map([
    ['default', () => 'Pay later'],
    ['DEU', () => 'Anschreiben lassen'],
    ['SPA', () => 'Añadir a la cuenta']
  ]),
  'PLEASE_WAIT': new Map([
    ['default', () => 'Please wait...'],
    ['DEU', () => 'Bitte warten...'],
    ['SPA', () => 'Espera por favor...']
  ]),
  'SAVE_CARD': new Map([
    ['default', () => 'Save card →'],
    ['DEU', () => 'Kreditkarte speichern →'],
    ['SPA', () => 'Guardar tarjeta →']
  ]),
  'EDIT': new Map([
    ['default', () => 'Edit'],
    ['SPA', () => 'Editar']
  ]),
  'CARD_NAME': new Map([
    ['default', () => 'Name on card'],
    ['SPA', () => 'Nombre en la tarjeta']
  ]),
  'CARD_NUMBER': new Map([
    ['default', () => 'Card number'],
    ['SPA', () => 'Número de la tarjeta']
  ]),
  'CARD_EXPIRES': new Map([
    ['default', () => 'Expires'],
    ['SPA', () => 'Caducidad']
  ]),
  'CARD_CVV': new Map([
    ['default', () => 'CVV']
  ]),
  'PAY': new Map([
    ['default', ({ element, externalGateways }) => {
      if (externalGateways.hasAny && externalGateways.has('GATEWAY_LATER')) {
        return new Map([
          ['DEU', element ? <><span>Für</span> {element} <span>bestellen</span></> : <span>Bestellen</span>],
          ['SPA', element ? <><span>Pedir por</span> {element}</> : <span>Pedir</span>]
        ]).get(window.stickySessionLanguage[0]) || (element ? <><span>Order now for</span> {element}</> : <span>Order now</span>)
      }
      return new Map([
        ['DEU', element ? <>{element} <span>bezahlen</span></> : <span>Fertig stellen</span>],
        ['SPA', element ? <><span>Pagar</span> {element}</> : <span>Finalizar</span>]
      ]).get(window.stickySessionLanguage[0]) || (element ? <><span>Pay</span> {element}</> : <span>Finish</span>)
    }],
    ['book-intermediate', ({ element }) => element ? <><span>Pay</span> {element}</> : <span>Finish</span>]
  ]),
  'PAY_SUBSCRIBE': new Map([
    ['default', ({ element }) => {
      return new Map([
        ['DEU', element ? <>{element} <span>bezahlen und abonnieren</span></> : <span>Abonnieren</span>],
        ['SPA', element ? <><span>Pagar</span> {element} <span>y suscribirse</span></> : <span>Suscribirse</span>]
      ]).get(window.stickySessionLanguage[0]) || (element ? <><span>Pay</span> {element} <span>and subscribe</span></> : <span>Subscribe</span>)
    }]
  ]),
  'FINISH_AND_PAY': new Map([
    ['default', ({ element, externalGateways }) => {
      if (externalGateways.hasAny && externalGateways.has('GATEWAY_LATER')) {
        return new Map([
          ['DEU', element ? <><span>Für</span> {element} <span>bestellen</span></> : <span>Bestellen</span>],
          ['SPA', element ? <><span>Pedir por</span> {element}</> : <span>Pedir</span>]
        ]).get(window.stickySessionLanguage[0]) || (element ? <><span>Order now for</span> {element}</> : <span>Order now</span>)
      }
      return new Map([
        ['DEU', element ? <><span>Fertig stellen und</span> {element} <span>bezahlen</span></> : <span>Fertig stellen</span>],
        ['SPA', element ? <><span>Finalizar y pagar</span> {element}</> : <span>Finalizar</span>]
      ]).get(window.stickySessionLanguage[0]) || (element ? <><span>Finish and pay</span> {element}</> : <span>Finish</span>)
    }],
    ['book-intermediate', ({ element }) => element ? <><span>Confirm booking for</span> {element}</> : <span>Confirm booking</span>]
  ]),
  'BUY_THIS_FOR': new Map([
    ['default', ({ product, quantity, showPrices, dynamicPreposition }) => {
      const prepositionFrom = dynamicPreposition ? getPrepositionFrom(product) : false
      return new Map([
        ['DEU', <>In den Einkaufswagen{showPrices && <> {prepositionFrom ? 'von' : 'für'} <Price currency={product.currency} price={product.realPrice * quantity} /></>}</>],
        ['SPA', <>Añadir{showPrices && <> {prepositionFrom ? 'desde' : 'por'} <Price currency={product.currency} price={product.realPrice * quantity} /></>}</>]
      ]).get(window.stickySessionLanguage[0]) || <>Add{showPrices && <> {prepositionFrom ? 'from' : 'for'} <Price currency={product.currency} price={product.realPrice * quantity} /></>}</>
    }],
    ['buy-now', ({ product, quantity, showPrices }) => {
      const prepositionFrom = getPrepositionFrom(product)
      return new Map([
        ['DEU', <>Bestellen{showPrices && <> {prepositionFrom ? 'von' : 'für'} <Price currency={product.currency} price={product.realPrice * quantity} /></>}</>],
        ['SPA', <>Comprar{showPrices && <> {prepositionFrom ? 'desde' : 'por'} <Price currency={product.currency} price={product.realPrice * quantity} /></>}</>]
      ]).get(window.stickySessionLanguage[0]) || <>Buy{showPrices && <> {prepositionFrom ? 'from' : 'for'} <Price currency={product.currency} price={product.realPrice * quantity} /></>}</>
    }],
    ['book-intermediate', ({ product, quantity, showPrices }) => <>Book now{showPrices && <> (<Price currency={product.currency} price={product.realPrice * quantity} />)</>}</>],
  ]),
  'AGAIN': new Map([
    ['default', () => {
      return new Map([
        ['DEU', { restartFlow: true, component: undefined }],
        ['SPA', { restartFlow: true, component: undefined }]
      ]).get(window.stickySessionLanguage[0]) || { restartFlow: true, component: undefined}
    }],
    ['buy--no-repeat', () => {
      return new Map([
        ['DEU', { restartFlow: true, component: undefined }],
        ['SPA', { restartFlow: true, component: undefined }]
      ]).get(window.stickySessionLanguage[0]) || { restartFlow: true, component: undefined}
    }],
    ['buy', () => {
      return new Map([
        ['DEU', { restartFlow: true, component: <>Erneut bestellen →</> }],
        ['SPA', { restartFlow: true, component: <>Ordenar de nuevo →</> }]
      ]).get(window.stickySessionLanguage[0]) || { restartFlow: true, component: <>Order again →</> }
    }],
    ['book-intermediate', () => ({
      restartFlow: false,
      component: <>Complete booking →</>
    })]
  ]),
  'OUT_OF_STOCK': new Map([
    ['default', ({ product, showPrices }) => {
      return new Map([
        ['DEU', <>Unverfügbar{showPrices && <> (<Price currency={product.currency} price={product.realPrice} />)</>}</>],
        ['SPA', <>Indisponible{showPrices && <> (<Price currency={product.currency} price={product.realPrice} />)</>}</>]
      ]).get(window.stickySessionLanguage[0]) || <>Out of stock{showPrices && <> (<Price currency={product.currency} price={product.realPrice} />)</>}</>
    }],
    ['book-intermediate', ({ product }) => {
      return new Map([
        ['DEU', <>Unverfügbar</>],
        ['SPA', <>Indisponible</>]
      ]).get(window.stickySessionLanguage[0]) || <>Unavailable</>
    }]
  ]),
  'DAY': new Map([
    ['default', () => 'day']
  ]),
  'WEEK': new Map([
    ['default', () => 'week']
  ]),
  'MONTH': new Map([
    ['default', () => 'month']
  ]),
  'YEAR': new Map([
    ['default', () => 'year']
  ]),
  'RESET_RECOVER': new Map([
    ['default', () => 'Forget me'],
    ['DEU', () => 'Zurücksetzen'],
    ['SPA', () => 'Restablecer']
  ])
}

export default function _ (mode, flowType, extras) {
  const foundMode = MODES[mode]
  if (typeof foundMode !== 'object') {
    return 'NOTHING_TO_SHOW'
  }
  return (foundMode.get(flowType) || foundMode.get('default') || (() => mode))(extras)
}
