import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { CustomHelmet } from '@openbox-app-shared'

import Reports from '../components/Reports'
import Box from '../components/Box'
import { log } from '../log'

const StyledRoute = styled.div`
  padding: 1rem;
`

export default class Route extends Component {
  constructor () {
    super()
    this.state = {}
  }

  async componentDidMount () {
    log('[Route-godMode] [componentDidMount]')
  }

  render () {
    const { user } = this.props

    return (
      <StyledRoute>
        <CustomHelmet
          title='Mr Tweedy'
        />
        <Box>
          <Reports user={user} reportId='godMode' />
        </Box>
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object
}
