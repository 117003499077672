import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, Price } from '@openbox-app-shared'
import ButtonGrid from '../../../../components/ButtonGrid'
import { dispatch } from '../../../../redux'

const StyledProducts = styled.div`
  .component--empty {
    padding-top: 1rem;
  }
  .component--button {
    background-size: cover;
    background-position: center center;
    border: 0;
    strong > * {
      display: block;
    }
    .product-name {
      line-height: 1.65rem;
    }
    .sub {
      margin-top: 0.75rem;
    }
  }
  .component--button.go-back {
    background-color: transparent;
    border: 2px solid black;
    color: black;
    font-size: 500%;
    > * {
      line-height: 1rem;
    }
  }
  .add-to-total {
    > svg {
      width: 2rem;
      height: 2rem;
    }
    > strong {
      line-height: 2rem !important;
    }
  }
`

function getStyle (url) {
  const style = {
    backgroundImage: url ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${url})` : undefined,
  }
  return style
}

function getProductCategoryButtonStyle (pc, filter = '', isForSale) {
  const style = pc.hasImageUrl && pc.showImageEpos ? getStyle(pc.imageUrl) : getStyle(undefined)
  const doesMatchFilter = (
    pc.doesMatchFilter(filter, false) ||
    pc.products.some(p => p.doesMatchFilter(filter, false))
  )
  return {
    ...style,
    color: pc.foregroundColor,
    opacity: doesMatchFilter && isForSale ? 1 : 0.5
  }
}

function getProductButtonStyle (p, filter = '') {
  const style = p.eposImageUrl ? getStyle(p.eposImageUrl) : getStyle(undefined)
  const shouldBeOpaque = !p.doesMatchFilter(filter, false) || !p.forSale
  return {
    ...style,
    opacity: !shouldBeOpaque ? 1 : 0.5
  }
}

export default function Products ({ filter, productCategories, onChoose, onActive }) {
  const [currentCategory, setCurrentCategory] = useState()
  return (
    <StyledProducts className='component--epos-products'>
      {typeof currentCategory === 'object' && (
        <ButtonGrid>
          <Button
            className='go-back'
            style={{ color: currentCategory.foregroundColor, borderColor: currentCategory.color, backgroundColor: currentCategory.color }}
            onClick={() => {
              setCurrentCategory(undefined)
              onActive && onActive()
              // scrollToTop()
              return
            }}
          >
            ←
          </Button>
          {currentCategory.products.filter(p => p.showInStickyretail2).map(p => {
            const style = getProductButtonStyle(p, filter)
            const notForSaleReason = p.notForSaleReason
            return (
              <Button
                key={`p--${p.id}`}
                onClick={() => {
                  onChoose(p)
                  // scrollToTop()
                }}
                disabled={!p.forSale}
                style={style}
                color={currentCategory.foregroundColor}
                backgroundColor={p.color || currentCategory.color}
              >
                <span className='product-name'>{p.name}</span>
                {notForSaleReason && <span className='sub'>{notForSaleReason}</span>}
                {!notForSaleReason && <Price className='sub' price={p.price} currency={p.currency} />}
              </Button>
            )
          })}
        </ButtonGrid>
      )}
      {typeof currentCategory !== 'object' && (
        <>
          <ButtonGrid columns={4}>
            <Button
              onClick={() => {
                dispatch('GET_PRICE', { why: 'epos--custom-total', price: 0 })
              }}
              inlineIconString={window.sticky.internals.icons.get('plus')}
              className='add-to-total'
            >
              Add to total
            </Button>
            {productCategories
              .map(pc => {
                const isForSale = pc.forSale()
                const style = getProductCategoryButtonStyle(pc, filter, isForSale)
                const notForSaleReason = pc.notForSaleReason()
                return (
                  <Button
                    key={`p--${pc.id}`}
                    onClick={() => {
                      onActive && onActive()
                      setCurrentCategory(pc)
                      // scrollToTop()
                    }}
                    disabled={!isForSale}
                    backgroundColor={pc.color}
                    style={style}
                  >
                    <span className='product-name'>{pc.name}</span>
                    {notForSaleReason && <span className='sub'>{notForSaleReason}</span>}
                  </Button>
                )
              })
            }
          </ButtonGrid>
        </>
      )}
    </StyledProducts>
  )
}

Products.propTypes = {
  user: PropTypes.object,
  filter: PropTypes.string,
  productCategories: PropTypes.arrayOf(PropTypes.object),
  onChoose: PropTypes.func.isRequired,
  onActive: PropTypes.func
}
