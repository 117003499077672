import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { icons, Time, Link, Details, Price, Button, LinkButton } from '@openbox-app-shared'
import EposCart from '../../routes/views/opsManager/components/Cart'
import Box from '../Box'

import _ from '../../_'
import { dispatch } from '../../redux'

import EVENT_TYPES from './EVENT_TYPES'
import EventType from './EventType'
import UserCircle from '../UserCircle'
import dashboardIcons from '../../icons'

const StyledEvent = styled.li`
  position: relative;
  > .component--box {
    padding-bottom: 0.75rem;
  }
  .extra {
    position: absolute;
    top: 0.75rem;
    right: 1rem;
    .component--time {
      display: inline-block;
      height: 2rem;
      line-height: 2rem;
      font-size: 80%;
      color: #6C7A89;
    }
  }
  .actions {
    margin-top: 0.75rem;
    > * {
      display: inline-block;
      vertical-align: top;
      margin: 0 0.5rem 0.5rem 0;
    }
    button {
      padding: 0.25rem;
    }
    .thing img {
      width: 15px;
    }
    .thin-icon {
      strong {
        margin-left: 0.4rem !important;
      }
    }
  }
  .actions + .component--details {
    margin-top: 0.5rem;
  }
  .actions + .component--details, .cart {
    margin-bottom: 0.25rem;
  }
  .component--event-type {
    margin-bottom: 0.5rem;
  }
  .component--details {
    width: 100%;
  }
  .component--details + .component--details {
    margin-top: 1rem;
  }
  .cart {
    margin-top: 1rem;
    padding: 0;
    max-width: 420px;
    font-size: 80%;
  }
  .indent-me {
    margin-left: 1rem;
  }
`

function questionsToDetails (questions, borderColor) {
  const questionsDetails = questions && questions.length > 0 && new Map(questions.map(ci => {
    return [ci.question, Array.isArray(ci.answer) ? ci.answer.join(', ') : ci.answer]
  }))
  return questionsDetails ? <Details details={questionsDetails} borderColor={borderColor} /> : undefined
}

const SUPPORTS_CUSTOM_DATA = [
  'CHECK_IN',
  'CHECK_OUT',
  'SESSION_CART_DISCOUNT'
]

export default function Event ({ event, user, things = [], applications = [], federatedUsers = [], allowGoTo = true }) {
  const borderColor = undefined
  const foundThing = typeof event.thingId === 'string' && things.find(t => t.id === event.thingId)
  const foundApplication = typeof event.applicationId === 'string' && applications.find(t => t.id === event.applicationId)
  const foundFederatedUser = typeof event.federatedUserId === 'string' && federatedUsers.find(t => t.id === event.federatedUserId)
  const allowGoToPayment = allowGoTo && typeof event.paymentId === 'string' && [
    'VOID_PRODUCT',
    'SESSION_CART_PAY_ITEM',
    'SESSION_CART_DISCOUNT',

    'SESSION_CART_PAY',
    'SESSION_CART_PAY_FAIL',
    'SESSION_CART_REFUND'
  ]
    .includes(event.type)

  const foundEventType = EVENT_TYPES.find(([type]) => type === event.type)
  if (!foundEventType) {
    return null
  }
  const eventTypeObject = foundEventType[1]
  const { toDetails, showSessionId = true } = eventTypeObject

  const baseMapStuff = [
    // ...(event.device ? [
    //   ['Device', event.device.toString()]
    // ] : []),
    // ...(event.location.toString() ? [
    //   ['Location', event.location.toString()]
    // ] : [])
  ]

  const details = typeof toDetails === 'function'
    ?
    new Map([
      ...baseMapStuff,
      ...toDetails(user, event.id, event.customData, event.linearData)
    ])
    :
    new Map([
      ...baseMapStuff,
      ...(SUPPORTS_CUSTOM_DATA.includes(event.type) && !event.customData.isEmpty ? event.customData.toHumanMap() : [])
    ])

  const customData = event.customData.get()
  typeof customData.referrer === 'string' && details.set('Referrer', <code>{customData.referrer}</code>)
  typeof customData.fromUrl === 'string' && details.set('From', <code>{customData.fromUrl}</code>)
  typeof customData.total === 'number' && details.set('Total', <Price currency={customData.currency} price={customData.total} />)
  typeof customData.productPrice === 'number' && details.set('Price', <Price currency={customData.productCurrency} price={customData.productPrice} />)
  typeof customData.refundAmount === 'number' && details.set('Amount', <Price currency={customData.refundCurrency} price={customData.refundAmount} />)
  typeof customData.quantity === 'number' && details.set('Quantity', customData.quantity)
  const questionsDetails = questionsToDetails(customData.questions, borderColor)
  questionsDetails && details.set('Questions', questionsDetails)

  return (
    <StyledEvent className='component--event'>
      <Box>
        <EventType key={event.id} event={event} user={user} />
        <div className='actions'>
          {allowGoTo && foundFederatedUser && (
            <Link disabled={!user.federatedUserCan('master')} to={`/me/team/${foundFederatedUser.id}`}><UserCircle fixedWidthHeight={2} name={foundFederatedUser.name} color={foundFederatedUser.color} photoUrl={foundFederatedUser.photoUrl} /></Link>
          )}
          {allowGoTo && foundThing && (
            <LinkButton disabled={!user.federatedUserCan('things')} isSecondary className='thing' sameTab to={`/me/stickies/${foundThing.id}`} icon={foundThing.designUrl}>{foundThing.name}</LinkButton>
          )}
          {allowGoTo && foundApplication && (
            <LinkButton disabled={!(user.federatedUserCan('applications') && user.federatedUserCan('applications-advanced'))} isSecondary sameTab to={`/me/flows/${foundApplication.id}`} icon={foundApplication.baseIcon}>{foundApplication.name}</LinkButton>
          )}
          {showSessionId && event.consumerIdentifier && <Button
            isSecondary
            icon={(event.getUserValue('Verified') || event.getUserValue('stickyVerified')) ? icons.generic.verify : icons.generic.unverify}
            className='thin-icon'
            onClick={() => {
              dispatch('LOADING')
              const { sessionId } = event
              window.sticky.session.get(undefined, false, sessionId)
                .then(session => {
                  dispatch('SESSION', { session, why: 'Event' })
                  dispatch('STOP_LOADING')
                })
            }}
          >
            {event.consumerIdentifier}
          </Button>}
          {event.locationClient && (
            <Button
              isSecondary
              className='thin-icon'
              InlineIcon={dashboardIcons.location}
              title='Map'
              onClick={() => dispatch('LOCATION', { location: event.locationClient })}
            >
              View on map
            </Button>
          )}
          {allowGoToPayment && (
            <LinkButton disabled={!user.federatedUserCan('payments')} isSecondary sameTab to={`/me/payments/${event.paymentId}`} InlineIcon={dashboardIcons.payment}>{window.sticky.Stickypay.getConsumerId(event.paymentId)}</LinkButton>
          )}
          {allowGoTo && event.product && (
            <LinkButton disabled={!user.federatedUserCan('master')} isSecondary sameTab to={`/me/products/${event.product.id}`} InlineIcon={dashboardIcons.product}>{event.product.name}</LinkButton>
          )}
        </div>
        <Details details={details} borderColor={borderColor} />
        {Array.isArray(event.customData.get().cart) && event.customData.get().cart.length > 0 && <Box key='dumb-key' className='cart'><EposCart cart={event.customData.get().cart} canVoid={false} /></Box>}
        <div className='extra'>
          {typeof event.id === 'string' && (
            <Time
              key='d--time'
              time={event.createdAt}
              onClick={() => dispatch('GET_INPUT', { string: event.id, selectAll: true })}
            />
          )}
        </div>
      </Box>
    </StyledEvent>
  )
}
Event.propTypes = {
  event: PropTypes.object,
  user: PropTypes.object,
  things: PropTypes.array,
  applications: PropTypes.array,
  federatedUsers: PropTypes.array,
  allowGoTo: PropTypes.bool
}
