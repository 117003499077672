import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { icons, Button, Price, Modal, LinkButton } from '@openbox-app-shared'

import { dispatch, subscribe } from '../../../../redux'
import { log } from '../../../../log'
import dashboardIcons from '../../../../icons'
import classnames from 'classnames'
import H1 from '../../../../components/H1'

const StyledSummary = styled.div`
  .grid {
    display: grid;
    grid-gap: 1rem;
  }
  .grid.grid-2 {
    grid-template-columns: 1fr 1fr;
  }
  .grid.grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .grid + .grid, .grid + div, div + .grid {
    margin-top: 1rem;
  }
  .headline {
    .total {
      display: block;
      text-align: center;
      font-size: 2.5rem;
      height: 56px;
      line-height: 56px;
      color: #1A1F35;
    }
  }
  .method-1 {
    .component--button {
      width: 100%;
      border-radius: 6px;
      height: 4rem;
      font-size: 140%;
      > strong {
        margin-top: 0;
      }
      > svg {
        width: 2rem;
        height: 2rem;
      }
      > strong {
        line-height: 2rem !important;
      }
    }
  }
  .method-2 {
    .component--button {
      width: 100%;
      height: 104px;
      border-radius: 6px;
      > strong {
        margin-top: 0.5rem;
      }
    }
  }
  h1 {
    margin-bottom: 1rem;
    text-align: center;
  }
`

export default function Summary ({
  user,
  userPreferences,
  payment,
  onAction,
  hasThings,
  hasThingOneVirtual,
  applicationsMoto,
  applicationsNotMoto
}) {
  const [statePay, setStatePay] = useState()
  const [doDestroyPaymentWhenClosingModal, setDoDestroyPaymentWhenClosingModal] = useState(false)
  let [lastPayThing, setLastPayThing] = useState()
  const [hasMadePreDecision, setHasMadePreDecision] = useState(false)

  const { currency, total } = payment || {}

  const { posCashCard = true, posCashCardCard = false } = userPreferences
  const canCard = payment.total > 0 && applicationsMoto.length > 0
  const canCash = payment.total > 0 && posCashCard
  const canGATEWAY_CARD = posCashCardCard
  const canCardCashArray = [canCard, canCash, canGATEWAY_CARD].filter(_ => _)

  useEffect(() => {
    if (!hasMadePreDecision && statePay && applicationsNotMoto.length > 0 && applicationsMoto.length === 0 && !hasThings && !canCard && !canCash && !user.can('has-printer')) {
      onLink()
      setHasMadePreDecision(true)
    }
    if (!hasMadePreDecision && statePay && applicationsNotMoto.length === 0 && applicationsMoto.length > 0 && !hasThings && canCard && !canCash && !user.can('has-printer')) {
      onCard()
      setHasMadePreDecision(true)
    }

    const subscriptions = [
      subscribe('HOW_TO_PAY_READY', () => {
        setStatePay(true)
        setDoDestroyPaymentWhenClosingModal(true)
      }),
      subscribe(
        'SHOW_MESSAGE_GOOD',
        ({ why }) => {
          why && why.startsWith('GATEWAY--') && (() => {
            why = why.substring('GATEWAY--'.length)
            setStatePay(false)
            setDoDestroyPaymentWhenClosingModal(false)
            onAction('pay', { toDo: why })
          })()
        }
      ),
      subscribe(
        'GET_PRICE_GOOD',
        ({ why, price }) => {
          why.startsWith('GATEWAY--') && (() => {
            const delta = price - total
            if (delta === 0) {
              why = why.substring('GATEWAY--'.length)
              setStatePay(false)
              setDoDestroyPaymentWhenClosingModal(false)
              onAction('pay', { toDo: why })
              return
            }
            dispatch(
              'SHOW_MESSAGE',
              {
                message: <p className='openbox--pulsing-2'>Now give <strong><Price price={delta} currency={currency} /></strong></p>,
                why,
                css: `
                  p {
                    font-size: 120%;
                    line-height: 1.5rem;
                  }
                `
              }
            )
          })()
        }
      ),
      subscribe(
        'PAY_GO_TO_ON_CHANGE',
        ({ k, v }) => {
          onAction('patch', { k, v }, false)
        }
      ),
      subscribe(
        'CHOOSE_THING_GOOD',
        ({ thing, why }) => {
          if (!thing) {
            return
          }
          why === 'epos--pay--thing' && (() => {
            lastPayThing = thing
            setLastPayThing(lastPayThing)
            dispatch('CHOOSE_APPLICATIONS', { why: 'epos--pay--thing', applicationIds: applicationsNotMoto.map(_ => _.id) })
          })()
          why === 'epos--GATEWAY_LATER' && (() => {
            onAction('epos--GATEWAY_LATER', { thing })
          })()
        }
      ),
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, string }) => {
          why === 'epos--extra' && onAction('extra', { string })
        }
      ),
      subscribe(
        'CHOOSE_APPLICATIONS_GOOD',
        ({ why, applications }) => {
          log('[CHOOSE_APPLICATIONS_GOOD]', { why, applications, lastPayThing })
          const newApplicationId = applications[0].id
          why === 'epos--onLink' && (() => {
            payment.application = applications[0]
            onAction('patch', { k: 'newStatusDoneDontSet', v: true }, false)
            dispatch('PAY_GO_TO', { why: 'epos', payment, userPreferences })
          })()
          why === 'epos--pay--thing' && (() => {
            onAction('pay', { toDo: 'sticky', thing: lastPayThing, newApplicationId })
          })()
          why === 'epos--moto' && (() => {
            dispatch('APPLICATION_FOR_PAY', { why: 'epos--moto', paymentId: payment.id, applicationId: newApplicationId })
          })()
        }
      ),
      subscribe(
        'APPLICATION_FOR_PAY_GOOD',
        ({ why, extra, paymentId }) => {
          why === 'epos--moto' && (() => {
            setStatePay(false)
            setDoDestroyPaymentWhenClosingModal(false)
            onAction('addPayment')
          })()
        }
      ),
      subscribe(
        'PAY_WAIT_ORIGIN_GOOD',
        () => {
          setStatePay(false)
          setDoDestroyPaymentWhenClosingModal(false)
          onAction('addPayment')
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  const onLink = () => {
    log('[onLink]')
    dispatch('CHOOSE_APPLICATIONS', { why: 'epos--onLink', applicationIds: applicationsNotMoto.map(_ => _.id) })
  }
  const onCard = () => {
    dispatch('CHOOSE_APPLICATIONS', { why: 'epos--moto', applicationIds: applicationsMoto.map(_ => _.id) })
  }
  const onCardMachine = () => {
    dispatch('SHOW_MESSAGE', { why: 'GATEWAY--GATEWAY_CARD', message: <><p><strong>The total is <Price price={total} currency={currency} /></strong></p><p>Was the payment successful?</p></>, canBeGooded: 'Yes', canBeBadded: '← Go back' })
  }
  const onCash = () => {
    const arg = {
      total: payment.total,
      currency: payment.currency
    }
    log('[onCash]', { arg })
    dispatch('GET_PRICE', { why: 'GATEWAY--GATEWAY_CASH', hint: <><strong>The total is <Price price={arg.total} currency={arg.currency} /></strong><strong>How much have you taken?</strong></>, price: 0 })
  }
  const onPayThing = () => {
    dispatch('CHOOSE_THING', { why: 'epos--pay--thing', hint: 'Which sticky should take the payment?' })
  }
  const onMove = () => {
    dispatch('CHOOSE_THING', { why: 'epos--GATEWAY_LATER', hint: 'Which sticky should this be assigned to?' })
  }

  const paymentCart = payment && payment.cart.get()
  const canPayOrTab = (() => {
    if (!payment) {
      return true
    }
    if (paymentCart.length > 0) {
      return false
    }
    if (payment.total === 0) {
      return true
    }
    return false
  })()


  log('[Epos->Summary]', { paymentCart, canCard, canCash, canGATEWAY_CARD, canCardCashArray })

  return (
    <StyledSummary>
      {statePay && <Modal
        onClose={() => {
          setStatePay(false)
          setHasMadePreDecision(false)
          if (doDestroyPaymentWhenClosingModal) {
            onAction('voidThenAddPayment')
            setDoDestroyPaymentWhenClosingModal(false)
          }
        }}
      >
        <H1><Price currency={currency} price={total} /></H1>
        <div className='grid grid-2'>
          <div className='method-2 sticky'>
            <Button
              bigIcon
              InlineIcon={!hasThingOneVirtual ? dashboardIcons.stickypay : undefined}
              icon={hasThingOneVirtual ? window.sticky.applications.blocks.getIcon('7078f355-74cf-4c5e-b713-02de63c44610') : undefined}
              onClick={onPayThing}
              disabled={!hasThings || applicationsNotMoto.length === 0}
            >
              {hasThingOneVirtual ? 'QR code' : 'Sticky'}
            </Button>
          </div>
          <div className='method-2 link'>
            <Button
              bigIcon
              InlineIcon={dashboardIcons.link}
              onClick={onLink}
              disabled={applicationsNotMoto.length === 0}
            >
              Link
            </Button>
          </div>
        </div>
        {canCardCashArray.length > 0 && <hr />}
        {canCardCashArray.length > 0 && <div className={`grid grid-${canCardCashArray.length}`}>
          {canCash && <div className='method-2 GATEWAY_CASH'>
            <Button
              onClick={onCash}
              bigIcon
              isSecondary
              icon={`${window.sticky.cdn}/gateways/icon--GATEWAY_CASH.svg`}
            >
              {window.sticky.Stickypay.GATEWAYS.find(_ => _.id === 'GATEWAY_CASH').name}
            </Button>
          </div>}
          {canCard && <div className='method-2 GATEWAY_CARD'>
            <Button
              onClick={onCard}
              bigIcon
              isSecondary
              icon={`${window.sticky.cdn}/gateways/icon--GATEWAY_CARD.svg`}
            >
              Enter card
            </Button>
          </div>}
          {canGATEWAY_CARD && <div className='method-2 GATEWAY_CARD'>
            <Button
              onClick={onCardMachine}
              bigIcon
              isSecondary
              icon={`${window.sticky.cdn}/gateways/icon--GATEWAY_CARD.svg`}
            >
              Machine
            </Button>
          </div>}
        </div>}
        {user.can('has-printer') && (
          <>
            <hr />
            <div className='grid method-3'>
              <Button
                InlineIcon={dashboardIcons.printer}
                isSecondary
                bigIcon
                onClick={() => onAction('print')}
              >
                Print
              </Button>
            </div>
          </>
        )}
        <hr />
        <div className='grid method-3'>
          <Button
            icon={icons.inverted.add}
            onClick={() => {
              setStatePay(false)
              setDoDestroyPaymentWhenClosingModal(false)
              onAction('addPayment')
            }}
          >
            New payment
          </Button>
          <Button
            InlineIcon={dashboardIcons.printer}
            isSecondary
            onClick={() => {
              window.sticky.popUpIframe({
                src: `${window.location.origin}/me/payments/${payment.id}?userPrivateKey=${user.privateKey}&userPublicKey=${user.publicKey}&autoUi=printNow`,
                maxWidth: '376px',
                maxHeight: '680px',
                showBlocker: false
              })
            }}
          >
            Print here
          </Button>
        </div>
      </Modal>}
      <div className='headline'>
        {<Price className='total total-1' currency={currency} price={total} />}
      </div>
      <div className='grid grid-2 method-1'>
        <Button
          onClick={() => {
            setStatePay(true)
          }}
          inlineIconString={window.sticky.internals.icons.get('check')}
          disabled={canPayOrTab}
          className={classnames({ 'openbox--pulsing-1': payment && payment.total > 0 })}
        >
          Pay
        </Button>
        <Button
          onClick={onMove}
          isSecondary
          InlineIcon={dashboardIcons.thing}
          disabled={canPayOrTab}
        >
          Tab
        </Button>
      </div>
    </StyledSummary>
  )
}

Summary.propTypes = {
  user: PropTypes.object,
  userPreferences: PropTypes.object,
  payment: PropTypes.object,
  onAction: PropTypes.func.isRequired,
  canMove: PropTypes.bool,
  hasThings: PropTypes.bool,
  hasThingOneVirtual: PropTypes.bool,
  applicationsMoto: PropTypes.array,
  applicationsNotMoto: PropTypes.array,
  onDiscount: PropTypes.func
}
