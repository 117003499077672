import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Banner from './Banner'

import styled from 'styled-components'
import isEmailValid from '../isEmailValid/isEmailValid'
import isPasswordValid from '../isPasswordValid/isPasswordValid'
import isPhoneNumberValid from '../isPhoneNumberValid/isPhoneNumberValid'

const { v4: uuid } = require('uuid')

const StyledSearchInput = styled.div`
.component--input {
  input {
    height: 2.5rem;
    font-size: 90%;
    box-shadow: 0 5px 10px 0 rgba(60,66,87, 0.06), 0 2px 4px 0 rgba(0,0,0, 0.06);
    border: 0;
  }
}
`

const StyledLabel = styled.label`
  display: block;
  width: 100%;
  font-size: 90%;
  color: #1A1F35;
  transition: color 0.3s ease-in-out;
  &[aria-disabled="true"] {
    opacity: 0.5;
  }
  svg {
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: -0.25rem;
    margin-right: 0.25rem;
  }
`

export function Label ({ children, ...props }) {
  return (
    <StyledLabel {...props}>
      <strong>{children}</strong>
    </StyledLabel>
  )
}

const StyledInput = styled.div`
  label + * {
    margin-top: 0.5rem;
  }
  &:focus-within {
    input, textarea {
      box-shadow: #cccbf0 0px 0px 0px 3px;
      outline: 0;
    }
  }
  &.invalid {
    input, textarea {
      background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0.05), rgba(255, 0, 0, 0.05));
      border-color: rgba(255, 0, 0, 0.25);
    }
  }
  input, textarea {
    display: block;
    width: 100%;
    padding: 0.5rem;
    appearance: none;
    -webkit-appearance: none;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
    border: 1px solid #e0e1e4;
    text-align: inherit;
    &::selection {
      background-color: #F0F1F3;
    }
    &[disabled] {
      opacity: 0.5;
    }
    border-radius: 4px;
    background-color: white;
    letter-spacing: 0;
  }
  input::placeholder, textarea::placeholder {
    color: #8B99A8;
  }
  textarea {
    height: calc(5rem + 1rem + 2px);
    line-height: 1.25rem;
  }
  &.type--code {
    textarea {
      height: 12rem;
      font-family: "Fira code", "Fira Mono", monospace;
      font-size: 0.8rem;
    }
  }
`

export function Input ({
  className,
  min,
  max,
  autocomplete,
  type,
  label,
  value = '', // fixes controlled components 'warning' when this is undefined then becomes a string
  autoFocus,
  selectAll,
  color,
  style = {},
  onRef, onReady, onChange, onDone, onIsValid,
  isValid,
  pattern,
  InlineIcon,
  ...props
}) {
  const [hasSelectedAll, setSelectedAll] = useState(false)

  const generatedUuid = uuid()
  const labelProps = {
    style: {
      color: color ? color : undefined
    }
  }
  const id = `component--input-${generatedUuid}`
  const inputProps = {
    ...props,
    spellCheck: type === 'code' ? false : (props.spellCheck === false ? 'false' : undefined),
    id,
    type,
    pattern: (type === 'password' ? '.{8,36}' : pattern),
    title: (type === 'password' ? '8 to 36 characters' : undefined),
    value: value,
    min: (typeof min === 'string' ? min : undefined),
    max: (typeof max === 'string' ? max : undefined),
    autoComplete: autocomplete,
    ref: (inputRef) => {
      if (inputRef !== null) {
        onRef(inputRef)
        onReady({
          autoFocus: (doSelectAll) => {
            window.sticky.shouldAutoFocus() && setTimeout(
              () => {
                inputRef.focus()
                doSelectAll && (() => {
                  document.execCommand('selectall')
                  setSelectedAll(true)
                })()
              },
              250
            )
          }
        })
        if (autoFocus && window.sticky.shouldAutoFocus()) {
          inputRef.focus()
        }
        if (autoFocus && selectAll && !hasSelectedAll) {
          document.execCommand('selectall')
          setSelectedAll(true)
        }
        inputProps.ref = inputRef
      }
    },
    onFocus: () => {
      selectAll && setTimeout(() => document.execCommand('selectall'), 0)
    },
    onChange: (event) => onChange(event.target.value),
    style
  }
  delete inputProps.storageSectorName
  delete inputProps.storageSector
  const {
    disabled
  } = props

  const classNames = classnames('component--input', `type--${type}`, className, { 'invalid': !isValid })
  return (
    <StyledInput className={classNames}>
      {label && <Label {...labelProps} htmlFor={id} aria-disabled={disabled}>
        {InlineIcon && <InlineIcon />}
        {label}
      </Label>}
      {(['textarea', 'code'].includes(type)) &&
        <textarea
          {...inputProps}
          type={undefined}
          onKeyDown={event => {
            if (typeof onDone === 'function' && (event.ctrlKey || event.shiftKey) && event.key === 'Enter') {
              event.preventDefault()
              onDone(value)
            }
            // if (event.key === 'Tab') {
            //   const oldSe = inputProps.ref.selectionStart
            //   event.preventDefault()
            //   onChange(
            //     value.substring(0, inputProps.ref.selectionStart) + "  " + value.substring(inputProps.ref.selectionStart)
            //   )
            //   setTimeout(
            //     () => {
            //       inputProps.ref.selectionStart = oldSe + 2
            //       inputProps.ref.selectionEnd = oldSe + 2
            //     },
            //     0
            //   )
            // }
          }}
        />
      }
      {(!['textarea', 'code'].includes(type)) &&
        <input
          {...inputProps}
          onKeyDown={(event) => {
            if (typeof onDone === 'function' && event.key === 'Enter') {
              event.preventDefault()
              onDone(value)
            }
          }}
        />
      }
    </StyledInput>
  )
}
Input.propTypes = SlugInput.propTypes = BDAccountNumberInput.propTypes = BDSortCodeInput.propTypes = EmailInput.propTypes = PhoneNumberInput.propTypes = PasswordInput.propTypes = {
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  type: PropTypes.string,
  label: PropTypes.node,
  value: PropTypes.string,
  checked: PropTypes.bool,
  autoFocus: PropTypes.bool,
  selectAll: PropTypes.bool,
  color: PropTypes.string,
  onRef: PropTypes.func,
  onReady: PropTypes.func,
  onChange: PropTypes.func,
  onIsValid: PropTypes.func,
  onDone: PropTypes.func,
  isValid: PropTypes.bool,
  InlineIcon: PropTypes.any
}
NumberInput.propTypes = PriceInput.propTypes = LatLongInput.propTypes = {
  ...Input.propTypes,
  value: PropTypes.number
}
UrlInput.propTypes = {
  ...Input.propTypes,
  enforceHttps: PropTypes.bool
}

Input.defaultProps = {
  type: 'text',
  disabled: false,
  required: false,
  checked: false,
  onRef: () => {},
  onReady: () => {},
  onChange: () => {},
  onIsValid: () => {},
  onDone: () => {},
  isValid: true
}
NumberInput.defaultProps = PriceInput.defaultProps = SlugInput.defaultProps = LatLongInput.defaultProps = BDAccountNumberInput.defaultProps = BDSortCodeInput.defaultProps = {
  ...Input.defaultProps
}
EmailInput.defaultProps = {
  ...Input.defaultProps,
  type: 'email',
  autocomplete: 'email'
}
UrlInput.defaultProps = {
  ...Input.defaultProps,
  type: 'url',
  autocomplete: 'url',
  spellCheck: false
}
PhoneNumberInput.defaultProps = {
  ...Input.defaultProps,
  type: 'tel',
  autocomplete: 'tel'
}
PasswordInput.defaultProps = {
  ...Input.defaultProps,
  type: 'password'
}

export function DynamicInput ({ type, ...props }) {
  const Component = ({
    'generic': Input,
    'textarea': Input,
    'email': EmailInput,
    'url': UrlInput,
    'phone': PhoneNumberInput,
    'password': PasswordInput,
    'price': PriceInput,
    'number': NumberInput
  })[type]
  return <Component {...props} type={type} />
}

export function NumberInput (props) {
  const { value, min = 0, max = 2147483647 } = props

  const getRenderedValue = (v) => typeof v === 'number' ? v.toString() : ''
  const isValid = s => {
    const v = Number(s)
    return {
      v,
      isReallyValid: !isNaN(v) && v >= min && v <= max
    }
  }

  const [renderedValue, setRenderedValue] = useState(getRenderedValue(value))
  if (getRenderedValue(props.value) !== renderedValue) {
    setRenderedValue(getRenderedValue(props.value))
  }

  console.log('[NumberInput]', { value, renderedValue, props, min, max })
  return (
    <Input
      {...props}
      type='number'
      min={min}
      max={max}
      value={renderedValue}
      isValid={isValid(renderedValue).isReallyValid}
      onChange={s => {
        const {v, isReallyValid } = isValid(s)
        setRenderedValue(s)
        console.log('[NumberInput] [onChange]', { s, v, isReallyValid })
        props.onIsValid(isReallyValid)
        if (isReallyValid) {
          props.onChange(v)
        }
      }}
    />
  )
}

export function BDAccountNumberInput ({ doValidate = true, ...props }) {
  const [renderedValue, setRenderedValue] = useState(props.value || '')
  console.log('[BDAccountNumberInput]', { props })
  const getIsValid = v => v.length === 0 || (v.match(/^(\d){6,8}$/) ? true : false)
  return (
    <Input
      {...props}
      value={renderedValue}
      isValid={doValidate ? getIsValid(renderedValue) : true}
      label={props.label || 'Bank account number (6-8 digits)'}
      style={{ maxWidth: '102px' }}
      onChange={value => {
        setRenderedValue(value)
        const isItValid = getIsValid(value)
        console.log('[BDAccountNumberInput] [onChange]', { value, value, isItValid })
        props.onIsValid(isItValid)
        if (isItValid) {
          props.onChange(value)
        }
      }}
    />
  )
}
export function BDSortCodeInput ({ doValidate = true, ...props }) {
  const [renderedValue, setRenderedValue] = useState(props.value || '')
  console.log('[BDSortCodeInput]', { props })
  const getIsValid = v => v.length === 0 || (v.match(/^[0-9]{2}[- ]?[0-9]{2}[- ]?[0-9]{2}$/) ? true : false)
  return (
    <Input
      {...props}
      value={renderedValue}
      isValid={doValidate ? getIsValid(renderedValue) : true}
      label={props.label || 'Bank sort code (****** / **-**-**)'}
      style={{ maxWidth: '96px' }}
      onChange={value => {
        setRenderedValue(value)
        const isItValid = getIsValid(value)
        console.log('[BDSortCodeInput] [onChange]', { value, value, isItValid })
        props.onIsValid(isItValid)
        if (isItValid) {
          props.onChange(value)
        }
      }}
    />
  )
}

export function PriceInput (props) {
  const [renderedValue, setRenderedValue] = useState(typeof props.value === 'number' ? (props.value / 100).toFixed(2) : '')
  console.log('[PriceInput]', { renderedValue, props })
  const isValid = s => {
    if (s === '') {
      return { v: 0, isReallyValid: false, asInteger: 0 }
    }
    const v = Number(s)
    const asInteger = Math.ceil((v * 100).toFixed(0))
    const isReallyValid = !isNaN(v) && (asInteger <= 2147483647)
    return { v, isReallyValid, asInteger }
  }
  return (
    <Input
      {...props}
      className={classnames(props.className, 'type--price')}
      value={renderedValue}
      isValid={isValid(renderedValue).isReallyValid}
      onChange={s => {
        setRenderedValue(s)
        const { v, isReallyValid, asInteger } = isValid(s)
        console.log('[PriceInput] [onChange]', { s, v, isReallyValid, asInteger })
        props.onIsValid(isReallyValid)
        if (isReallyValid) {
          console.log('[PriceInput] [onChange] isReallyValid=true')
          props.onChange(asInteger)
        }
      }}
    />
  )
}

export function SlugInput (props) {
  const [renderedValue, setRenderedValue] = useState(props.value || '')
  console.log('[SlugInput]', { renderedValue, props })
  return (
    <Input
      {...props}
      value={renderedValue}
      onChange={value => {
        setRenderedValue(value)
        const isItValid = value.match(/^[0-9a-z-_]{1,50}$/)
        console.log('[SlugInput] [onChange]', { value, value, isItValid })
        props.onIsValid(isItValid)
        if (isItValid) {
          props.onChange(value)
        }
      }}
    />
  )
}

export function LatLongInput (props) {
  const getRenderedValue = (v) => typeof v === 'number' ? v.toString() : ''
  const [renderedValue, setRenderedValue] = useState(getRenderedValue(props.value))
  if (getRenderedValue(props.value) !== renderedValue) {
    setRenderedValue(getRenderedValue(props.value))
  }
  console.log('[LatLongInput]', { props, renderedValue })
  return (
    <Input
      {...props}
      value={renderedValue}
      onChange={value => {
        value = value.substring(0, '-'.length + 3 + '.'.length + 6)
        setRenderedValue(value)
        const isItValid = value.match(/^[-]{0,1}[0-9]{1,3}.[0-9]{1,10}$/)
        console.log('[LatLongInput] [onChange]', { value, isItValid })
        props.onIsValid(isItValid)
        if (isItValid) {
          props.onChange(Number(value))
        }
      }}
    />
  )
}

export function EmailInput ({ fireIfInvalid = false, ...props }) {
  const { isValid } = props
  const [renderedValue, setRenderedValue] = useState(props.value || '')
  const getIsValid = (v) => isEmailValid(v)
  const normaliseValue = v => v.toLowerCase()
  console.log('[EmailInput]', { renderedValue, fireIfInvalid, props })

  return (
    <Input
      {...props}
      value={renderedValue}
      isValid={typeof isValid === 'boolean' ? isValid : getIsValid(renderedValue)}
      onChange={value => {
        const normalisedValue = normaliseValue(value)
        setRenderedValue(normalisedValue)
        const isItValid = getIsValid(normalisedValue)
        console.log('[EmailInput] [onChange]', { value, normalisedValue, isItValid })
        props.onIsValid(isItValid)
        if (isItValid || fireIfInvalid) {
          props.onChange(normalisedValue)
        }
      }}
    />
  )
}

export function UrlInput ({ doValidate = true, enforceHttps, ...props }) {
  const [renderedValue, setRenderedValue] = useState(props.value || '')
  const getIsValid = (v) => {
    if (v.length > 0 && enforceHttps === true) {
      return v.startsWith('https://')
    }
    if (!doValidate) {
      return true
    }
    return (v.startsWith('http://') || v.startsWith('https://'))
  }
  const getIsInsecure = (v) => v.length > 0 && !v.startsWith('https://')

  const isInsecure = getIsInsecure(renderedValue)
  console.log('[UrlInput]', { renderedValue, doValidate, isInsecure })
  const bannerStyle = { marginBottom: '1rem' }
  return (
    <>
      {isInsecure && enforceHttps === undefined && <Banner style={bannerStyle}><p>People will see a security warning because this URL starts with <code>http://</code>. Use a URL that starts with <code>https://</code>.</p></Banner>}
      {isInsecure && enforceHttps === true && <Banner style={bannerStyle}><p>The URL must start with <code>https://</code>.</p></Banner>}
      <Input
        {...props}
        value={renderedValue}
        isValid={getIsValid(renderedValue)}
        onChange={value => {
          setRenderedValue(value)
          const isItValid = getIsValid(value)
          console.log('[UrlInput] [onChange]', { value, isItValid })
          props.onIsValid(isItValid)
          if (isItValid) {
            props.onChange(value)
          }
        }}
      />
    </>
  )
}

export function SearchInput ({ onChange, ...props }) {
  const [filter, setFilter] = useState('')
  return (
    <StyledSearchInput className='component--input search'>
      <Input
        value={filter}
        placeholder='Search...'
        autocomplete='off'
        onChange={v => {
          setFilter(v)
          onChange(v)
        }}
        {...props}
      />
    </StyledSearchInput>
  )
}

export function PhoneNumberInput ({ doValidate = true, fireIfInvalid = false, ...props }) {
  const [renderedValue, setRenderedValue] = useState(props.value || '')
  const getIsValid = (v) => doValidate ? isPhoneNumberValid(v) : true
  console.log('[PhoneNumberInput]', { renderedValue, props })
  return (
    <Input
      {...props}
      value={renderedValue}
      isValid={getIsValid(renderedValue)}
      onChange={value => {
        const normalisedValue = value.toLowerCase()
        setRenderedValue(normalisedValue)
        const isItValid = getIsValid(normalisedValue)
        console.log('[PhoneNumberInput] [onChange]', { value, normalisedValue, isItValid })
        props.onIsValid(isItValid)
        if (isItValid || fireIfInvalid) {
          props.onChange(normalisedValue)
        }
      }}
    />
  )
}

export function PasswordInput ({ fireIfInvalid = false, ...props }) {
  const [renderedValue, setRenderedValue] = useState(props.value || '')
  const getIsValid = (v) => isPasswordValid(v)
  console.log('[PasswordInput]', { renderedValue, props })
  return (
    <Input
      {...props}
      value={renderedValue}
      isValid={getIsValid(renderedValue)}
      onChange={value => {
        const normalisedValue = value
        setRenderedValue(normalisedValue)
        const isItValid = getIsValid(normalisedValue)
        console.log('[PasswordInput] [onChange]', { value, normalisedValue, isItValid })
        props.onIsValid(isItValid)
        if (isItValid || fireIfInvalid) {
          props.onChange(normalisedValue)
        }
      }}
    />
  )
}
