/* eslint-disable quotes */

const MODES = {
  'PAYMENTS': new Map([
    ['default', "Payments"]
  ]),
  'SETTINGS': new Map([
    ['default', "Settings"]
  ]),
  'ADVANCED': new Map([
    ['default', "Advanced settings"]
  ]),

  'THINGS': new Map([
    ['default', "Stickies"]
  ]),
  'THINGS_L_MANY': new Map([
    ['default', "stickies"]
  ]),
  'THINGS_L_ONE': new Map([
    ['default', "sticky"]
  ]),
  'THING': new Map([
    ['default', "Sticky"]
  ]),
  'CHOOSE_THING': new Map([
    ['default', "Which sticky?"]
  ]),
  'THING_L': new Map([
    ['default', "sticky"]
  ]),
  'GET_THINGS_MORE': new Map([
    ['default', "Sticky Store"]
  ]),
  'NO_THINGS': new Map([
    ['default', "You don't have any stickies yet."]
  ]),
  'NEW_PRODUCT': new Map([
    ['default', "Add a product"]
  ]),
  'NEW_SUB_PRODUCTS': new Map([
    ['default', "Add sub products"]
  ]),
  'NEW_PRODUCT_CATEGORY': new Map([
    ['default', "Add a category"]
  ]),

  'FEDERATED_USERS': new Map([
    ['default', "Team"]
  ]),
  'FEDERATED_USER': new Map([
    ['default', "Team member"]
  ]),
  'FEDERATED_USER_ADD': new Map([
    ['default', "Add a team member"]
  ]),
  'CHOOSE_FEDERATED_USER': new Map([
    ['default', "Choose a team member"]
  ])
}

export default function _ (mode, locale = 'default') {
  const foundMode = MODES[mode]
  if (typeof foundMode !== 'object') {
    return 'NOTHING_TO_SHOW'
  }
  return foundMode.get(locale) || mode
}
