import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dashboardIcons from '../icons'

import { icons, Price, Button, PatchableSetList, List, ListItem, Banner } from '@openbox-app-shared'
import Box from './Box'
import H2 from './H2'
import _ from '../_'

const StyledComponent = styled.div`
  .component--patchable + .component--button {
    margin-top: 1rem;
  }
  .component--list + .component--box {
    margin-top: 1rem;
  }
  .component--banner {
    margin-top: 1rem;
    margin-bottom: 0 !important;
  }
`

export default function SubProducts ({ skipProductIds = [], subProducts, allProducts, onChange }) { 
  const [hackReRender, setHackReRender] = useState(0)
  const [currentNewSubProductFilter, setCurrentNewSubProductFilter] = useState('')
  const [currentNewSubProducts, setCurrentNewSubProducts] = useState(window.sticky.newPatchableSet([]))

  const forPs = allProducts
    .filter(_ => !skipProductIds.includes(_.id) && _.doesMatchFilter(currentNewSubProductFilter, false))
    .map(p => {
      return {
        id: p.id,
        name: p.name,
        tags: [
          {
            name: <Price price={p.price} currency={p.currency} />
          }
        ]
      }
    })

  return (
    <StyledComponent className='component--sub-products'>
      <List>
        {subProducts
          .map(sp => allProducts.find(p => p.id === sp))
          .filter(_ => _)
          .map((sp, i) => {
            return (
              <ListItem
                id={`${i}--${sp.id}`}
                key={`${i}--${sp.id}`}
                InlineIcon={dashboardIcons.product}
                actions={['delete']}
                onAction={(_index, action) => {
                  action === 'delete' && (() => {
                    subProducts = subProducts.filter((__, siI) => siI !== i)
                    onChange(subProducts)
                  })()
                }}
              >
                {sp.name}
              </ListItem>
            )
          })}
      </List>
      <Box>
        <H2>{_('NEW_SUB_PRODUCTS')}</H2>
        <PatchableSetList
          filter={currentNewSubProductFilter}
          setFilter={setCurrentNewSubProductFilter}
          all={forPs}
          set={currentNewSubProducts}
          onUpdate={(ns) => {
            setCurrentNewSubProducts(ns)
            setHackReRender(hackReRender + 1)
          }}
          limitHeight={320}
        />
        <Button
          backgroundColor='#a55eea'
          onClick={() => {
            subProducts = [...subProducts, ...currentNewSubProducts.toArray()]
            onChange(subProducts)
          }}
          icon={icons.inverted.add}
        >
          {_('NEW_SUB_PRODUCTS')}
        </Button>
      </Box>
      <Banner mood='good'>
        <p>
          "Email me when for sale and stock is below" doesn't apply to sub products.
        </p>
      </Banner>
    </StyledComponent>
  )
}
SubProducts.propTypes = {
  skipProductIds: PropTypes.array,
  subProducts: PropTypes.array,
  allProducts: PropTypes.array,
  onChange: PropTypes.func
}
