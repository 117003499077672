import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { isEmailValid, isPasswordValid, ExpandCollapse, EmailInput, PasswordInput, Button, Form, Banner, Empty, List, ListItem, LinkButton } from '@openbox-app-shared'
import dashboardIcons from '../../icons'
import FederatedUsersGrid from '../../components/FederatedUsersGrid'
import Box from '../../components/Box'
import H2 from '../../components/H2'
import UserCircle from '../../components/UserCircle'
import { dispatch, subscribe } from '../../redux'
import getHomeUrl from '../../getHomeUrl'

const StyledThisComponent = styled.div`
  .component--form > .component--box .component--list {
    max-height: calc(100vh - 20rem);
    overflow: scroll;
  }
  .component--form > :not(.buttons) {
    width: auto;
    margin-right: 1rem;
  }
  .component--form > * + * {
    margin-top: 1.5rem;
  }
  .buttons {
    float: right;
    width: auto;
    > * {
      display: inline-block;
      vertical-align: top;
      margin: 0 1rem 1rem 0;
    }
  }
  .component--h2 {
    text-align: center;
    line-height: 1.5rem;
  }
  .component--empty {
    p {
      line-height: 1.25rem;
    }
  }
  .stage--federated-users {
    .component--federated-users-grid {
      width: fit-content;
      > * {
        width: 3rem;
        height: 3rem;
      }
    }
  }
  .stage--password {
    .component--user-circle {
      width: 4rem;
      margin: 0 auto 0 auto;
      padding-right: 1rem;
    }
  }
  a.switch-state {
    height: 2.5rem;
    line-height: 2.5rem;
    text-decoration: none;
    color: #1A1F35;
    outline: 0;
    border-radius: 4px;
  }
  a.switch-state:focus {
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0 2px 5px 0 rgb(60 66 87 / 12%), 0 1px 1px 0 rgb(0 0 0 / 12%), #cccbf0 0px 0px 0px 3px;
  }
  .component--button.sign-up {
    display: block;
    margin: 0 auto 0 auto;
  }
`

function getFreshData () {
  const urlObject = new URL(window.location.href)
  const maybeEmail = urlObject.searchParams.get('email')
  const data = {
    email: isEmailValid(maybeEmail) ? maybeEmail : ''
  }
  return data
}

const backButtonProps = {
  // color: 'white',
  backgroundColor: 'transparent'
}

const nextButtonProps = {
  // color: '#322CBE',
  // backgroundColor: 'white'
}

function getFederatedUsers (publicKey, userEmail) {
  return window.sticky.users.federated.getAll(true, `public:${publicKey}`, true, userEmail)
}

const STAGES = [
  {
    name: 'stage--user',
    children: ({ partnerCode, autoUi, isBusy, setIsBusy, data, onUpdate, onNext }) => {
      async function goNext () {
        setIsBusy(true)
        const users = await window.sticky.users.getByEmail(data.email, true)
        if (users.length === 1) {
          const federatedUsers = await getFederatedUsers(users[0].publicKey, data.email)
          const foundFuWithEmail = federatedUsers.find(_ => _.email)
          if (foundFuWithEmail) {
            onNext(3, { users, user: users[0], federatedUsers, federatedUser: foundFuWithEmail })
          } else {
            onNext(2, { users, user: users[0], federatedUsers, federatedUserEmail: foundFuWithEmail ? foundFuWithEmail.email : undefined })
          }
        } else {
          onNext(1, { users })
        }
      }
      const canGoNext = [
        isEmailValid(data.email)
      ].every(e => e)
      return (
        <Form
          className='stage--user'
          onChange={(key, value) => onUpdate({ [key]: value })}
          onSubmit={goNext}
          autoFocus
        >
          <EmailInput
            placeholder='sales@acme.co'
            name='email'
            value={data.email}
            label='Email'
            isValid
          />
          <div className='buttons'>
            <Button {...nextButtonProps} disabled={!canGoNext || isBusy} onClick={goNext} className={canGoNext ? 'openbox--pulsing-2' : undefined}>
              Next →
            </Button>
          </div>
        </Form>
      )
    }
  },
  {
    name: 'stage--users',
    children: ({ setIsBusy, data, onNext, onPrevious }) => {
      return (
        <Form
          className='stage--users'
          autoFocus
        >
          {data.users.length > 1 && <H2>Choose a dashboard</H2>}
          {data.users.length === 0 && (
            <Box><Empty>There are no dashboards for <strong>{data.email}</strong>.</Empty>{!window.ReactNativeWebView && <LinkButton to='/sign-up' sameTab className='sign-up openbox--pulsing-2'>Sign up →</LinkButton>}</Box>
          )}
          {data.users.length > 0 && (
            <Box>
              <List>
                {data.users.map(u => {
                  return (
                    <ListItem
                      key={u.publicKey}
                      id={u.publicKey}
                      InlineIcon={dashboardIcons.teamMember}
                      onChoose={async () => {
                        setIsBusy(true)
                        const federatedUsers = await getFederatedUsers(u.publicKey)
                        onNext(1, { user: u, federatedUsers })
                      }}
                    >
                      {u.name}
                    </ListItem>
                  )
                })}
              </List>
            </Box>
          )}
          <div className='buttons'>
            <Button {...backButtonProps} onClick={() => onPrevious()} isSecondary>
              ← Back
            </Button>
          </div>
        </Form>
      )
    }
  },
  {
    name: 'stage--federated-users',
    children: ({ data, onUpdate, onNext, onPrevious }) => {
      const canGoNext = [
        isEmailValid(data.federatedUserEmail)
      ].every(e => e)
      return (
        <>
          <Form
            className='stage--federated-users'
            onChange={(key, value) => {
              onUpdate({ [key]: value })
            }}
            onSubmit={onNext}
            autoFocus
          >
            <EmailInput
              name='federatedUserEmail'
              label='Your team member email'
              value={data.federatedUserEmail}
              placeholder='me@acme.co'
              required
              fireIfInvalid
            />
            <ExpandCollapse
              text='Choose by initials'
            >
              <FederatedUsersGrid
                federatedUsers={data.federatedUsers}
                onChoose={federatedUser => onNext(1, { federatedUser, federatedUserEmail: undefined })}
                showEmpty={false}
              />
            </ExpandCollapse>
            <div className='buttons'>
              <Button {...backButtonProps} onClick={() => onPrevious(data.users.length === 1 ? 2 : 1)} isSecondary>
                ← Back
              </Button>
              <Button
                {...nextButtonProps}
                disabled={!canGoNext}
                onClick={() => {
                  onNext(1, { federatedUser: undefined })
                }}
                className={canGoNext ? 'openbox--pulsing-2' : undefined}
              >
                Next →
              </Button>
            </div>
          </Form>
        </>
      )
    }
  },
  {
    name: 'stage--password',
    children: ({ logInRedirectTo, isBusy, setIsBusy, data, onUpdate, onPrevious }) => {
      async function goNext () {
        setIsBusy(true)
        try {
          const { federatedUserId, federatedUserRoles, userPermissions } = await window.sticky.users.logIn(data.user.publicKey, data.federatedUserEmail || data.federatedUser.id, data.password)
          window.location = (() => {
            if (logInRedirectTo) {
              return `${logInRedirectTo}?logOutRedirectTo=${logInRedirectTo}`
            }
            return getHomeUrl(federatedUserId, federatedUserRoles)
          })()
        } catch (e) {
          const { message } = e
          dispatch('SHOW_MESSAGE', { message: <p>{message}</p>, canBeBadded: '' })
        } finally {
          setIsBusy(false)
        }
      }
      const canGoNext = [
        isPasswordValid(data.password)
      ].every(e => e)
      return (
        <Form
          className='stage--password'
          onChange={(key, value) => onUpdate({ [key]: value })}
          onSubmit={goNext}
        >
          {data.federatedUser && <>
            <div><UserCircle name={data.federatedUser.name} color={data.federatedUser.color} /></div>
            <div><input type='hidden' name='email' autoComplete='email' value={data.federatedUserEmail || data.email} /></div>
          </>}
          <PasswordInput
            name='password'
            key='password'
            label='Your password'
            value={data.password}
            autocomplete='current-password'
            fireIfInvalid
            onReady={({ autoFocus }) => autoFocus()}
          />
          <Banner mood='good'>
            <p>
              Haven't set a password yet?
            </p>
            <Button
              {...backButtonProps}
              disabled={isBusy}
              onClick={() => {
                setIsBusy(true)
                dispatch('TRIGGER', { trigger: 'uberPublic--resetPassword', body: { userPublicKey: data.user.publicKey, idOrEmail: data.federatedUserEmail || data.federatedUser.id } })
              }}
              isSecondary
            >
              Reset password
            </Button>
          </Banner>
          <div className='buttons'>
            <Button
              {...backButtonProps}
              disabled={isBusy}
              onClick={onPrevious}
              isSecondary
            >
              ← Back
            </Button>
            <Button {...nextButtonProps} disabled={!canGoNext || isBusy} onClick={goNext} className={canGoNext ? 'openbox--pulsing-2' : undefined}>
              Log in →
            </Button>
          </div>
        </Form>
      )
    }
  },
]

export default function ThisComponent ({ autoUi, partnerCode, logInRedirectTo, style }) {
  const [data, setData] = useState(getFreshData())
  const [stageIndex, setStageIndex] = useState(0)
  const [isBusy, setIsBusy] = useState(false)
  const currentStage = STAGES[stageIndex]

  useEffect(() => {
    const subscriptions = [
      subscribe(
        'TRIGGER_GOOD',
        ({ trigger }) => {
          trigger === 'uberPublic--resetPassword' && (() => {
            setIsBusy(false)
          })()
        }
      ),
      subscribe(
        'TRIGGER_BAD',
        ({ trigger }) => {
          trigger === 'uberPublic--resetPassword' && (() => {
            setIsBusy(false)
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  const onUpdate = delta => {
    const newData = {
      ...data,
      ...delta
    }
    setData(newData)
  }
  return (
    <StyledThisComponent style={style}>
      <currentStage.children
        autoUi={autoUi}
        partnerCode={partnerCode}
        logInRedirectTo={logInRedirectTo}
        data={data}
        onUpdate={onUpdate}
        isBusy={isBusy}
        setIsBusy={setIsBusy}
        onPrevious={(howManySteps = 1) => setStageIndex(stageIndex - howManySteps)}
        onNext={(howManySteps = 1, delta) => {
          typeof delta === 'object' && setData({ ...data, ...delta, password: '' })
          setIsBusy(false)
          setStageIndex(stageIndex + howManySteps)
        }}
      />
    </StyledThisComponent>
  )
}
ThisComponent.propTypes = {
  autoUi: PropTypes.arrayOf(PropTypes.string),
  partnerCode: PropTypes.string,
  logInRedirectTo: PropTypes.string,
  style: PropTypes.object
}
