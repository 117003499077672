/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styled, { css } from 'styled-components'

import { tags } from '../components/Tag'
import ApplicationBlocks from './ApplicationBlocks'
import Button from './Button'
import { ExpandCollapse } from './ExpandCollapse'

const StyledQuestion = styled.div`
  position: relative;
  ${props => css`
    &.in-a-box:not(.is-checklist) {
      border-radius: 6px;
      box-shadow: 0 2px 5px 0 ${props.closeColor || '#1A1F35'}40;
      padding: 1rem;
    }
    .component--expand-collapse {
      &:focus-visible {
        outline: none;
      }
      &:focus-within {
        box-shadow:none;
      }
      > summary {
        font-size: 90%;
      }
      > div {
        padding-top: 0;
      }
    }
  `}
  &.type--option {
    .component--dropdown {
      margin-bottom: 0 !important;
    }
    .component--button {
      margin-top: 0.5rem !important;
      margin-bottom: 0 !important;
    }
  }
  &.type--options:not(.is-checklist) {
    padding-bottom: 0.75rem;
  }
  &.type--options .component--expand-collapse > div {
    padding-top: 0;
    padding-bottom: 0.75rem;
  }
  &.type--options {
    .component--application-blocks > *[data-application-block-id='form--checklist'] {
      margin-bottom: 0 !important;
      label {
        margin-bottom: 12px !important;
      }
    }
  }
  .component--dropdown {
    margin-bottom: 0.5rem;
    label {
      text-align: center;
    }
  }
  .description {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 80%;
    line-height: 1rem;
  }
  .can-be-duplicated-text {
    min-height: 2rem;
    padding: 0rem;
    margin-top: 0 !important;
    margin-bottom: 0.5rem;
  }
  .icon--close {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 2.5rem;
    height: 2.5rem;
    background-color: transparent;
    transition: color 0.1s ease-in-out;
    outline: 0;
    ${props => css`
      color: ${props.closeColor || '#1A1F35'};
    `}
    svg {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      margin: 0.5rem;
    }
  }
  .ab---5dc584b0-b0ef-4e4f-a8cc-476e3c8fc36b > *:first-child {
    margin-bottom: 0 !important;
  }
`

function ChooseOneContainer({ otherColor, color, question, children }) {
  return <div>{children}</div>
}

function ChecklistContainer({ otherColor, color, question, children }) {
  const isExpanded = question.checklistMinimum > 0
  return <ExpandCollapse isCollapsed={!isExpanded} backgroundColor={otherColor} color={isExpanded ? color : `${color}`} text={<strong>{question.question}</strong>}>{children}</ExpandCollapse>
}

ChecklistContainer.propTypes = ChooseOneContainer.propTypes = {
  otherColor: PropTypes.string,
  color: PropTypes.string,
  question: PropTypes.object,
  children: PropTypes.node
}

export default function Question({ question, masterReference, color = '#1A1F35', otherColor = '#ffffff', onDuplicate, onRemove, currency, inABox = true, showPrices = true }) {
  console.log('[Question]', { qa: question.answer.toString(), color, otherColor })
  const currentlySelectedOptions = Array.isArray(question.answer) ? question.options.filter(o => question.answer.includes(o.name)) : [question.options.find(o => o.name === question.answer)]
  let theTags = []
  currentlySelectedOptions.forEach(o => {
    theTags = [
      ...theTags,
      ...(tags.filter(t => !theTags.find(existingTheTag => existingTheTag.id === t.id) && t.doesAConsumerCare && (o.tags || []).includes(t.id)))
    ]
  })
  const Container = question.type === 'options' ? ChecklistContainer : ChooseOneContainer

  return (
    <StyledQuestion className={classnames(`component--question type--${question.type}`, { 'in-a-box': inABox, 'is-checklist': question.type === 'options' })} closeColor={color}>
      <Container otherColor={otherColor} color={color} question={question}>
        {question.type === 'option' && (
          <ApplicationBlocks
            className='ab---5dc584b0-b0ef-4e4f-a8cc-476e3c8fc36b'
            applicationBlocks={[
              {
                id: '5dc584b0-b0ef-4e4f-a8cc-476e3c8fc36b',
                config: {
                  masterReference,
                  howTo: 'Custom',
                  customValue: question.answer,
                  label: question.question,
                  doAnimate: false,
                  // columns: question.options.length === 3 ? question.options.length : 2,
                  columns: 2,
                  list: question.options.map(o => {
                    const media = o.media.filter(__ => !__.showInLinearOperatorView)
                    return {
                      name: o.name,
                      // nameMore: showPrices ? (o.delta > 0 ? window.sticky.Stickypay.formatPrice(currency, o.delta) : undefined) : undefined,
                      imageUrl: (media[0] ? media[0].url : undefined)
                    }
                  })
                }
              }
            ]}
          />
        )}
        {question.type === 'options' && (
          <ApplicationBlocks
            className='ab---form--checklist'
            applicationBlocks={
              [
                (question.checklistMinimum > 0 || question.checklistMaximum) && {
                  'id': '71d05208-3781-4c24-996e-c4c0d1c6b228',
                  'config': {
                    'what': (() => {
                      if (question.checklistMinimum > 0 && question.checklistMaximum && question.checklistMinimum === question.checklistMaximum) {
                        return `Choose exactly ${question.checklistMinimum}`
                      }
                      return `Choose ${[question.checklistMinimum > 0 && `at least ${question.checklistMinimum}`, question.checklistMaximum && `at most ${question.checklistMaximum}`].filter(_ => _).join(' and ')}`
                    })(),
                    'font': `${color}--center--100%--false`,
                    'doAnimate': false
                  }
                },
                {
                  'id': 'form--checklist',
                  'config': {
                    'masterReference': masterReference,
                    'list': question.options.map(o => o.name),
                    'listRightHandSide': showPrices ? question.options.map((o, i) => o.delta > 0 ? window.sticky.Stickypay.formatPrice(currency, o.delta) : '') : question.options.map(_ => ''),
                    'listSelected': question.answer,
                    'doAnimate': false,
                    'checklistMinimum': question.checklistMinimum,
                    'checklistMaximum': question.checklistMaximum
                  }
                }
              ]
                .filter(_ => _)
            }
          />
        )}
        {currentlySelectedOptions
          .filter(o => o.description)
          .map(o => {
            return (
              <p key={o.name} className='description'>{o.description}</p>
            )
          })}
        {theTags.length > 0 && (
          <ApplicationBlocks
            applicationBlocks={[
              {
                id: 'tags',
                config: {
                  tags: theTags
                }
              }
            ]}
          />
        )}
        {onDuplicate && (
          <Button
            className='can-be-duplicated-text dont-munge-me'
            backgroundColor={color}
            color={otherColor}
            inlineIconString={window.sticky.internals.icons.get('plus')}
            onClick={onDuplicate}
          >
            {question.canBeDuplicatedText}
          </Button>
        )}
        {onRemove && (
          <button
            onClick={onRemove}
            title='Close'
            className="icon--close"
          >
            <svg fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeWidth="3" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m18 6-12 12" /><path d="m6 6 12 12" /></svg>
          </button>
        )}
      </Container>
    </StyledQuestion>
  )
}
Question.propTypes = {
  question: PropTypes.object.isRequired,
  masterReference: PropTypes.number,
  answer: PropTypes.string,
  color: PropTypes.string,
  otherColor: PropTypes.string,
  onDuplicate: PropTypes.func,
  onRemove: PropTypes.func,
  currency: PropTypes.string,
  inABox: PropTypes.bool,
  showPrices: PropTypes.bool
}
