import React from 'react'
import styled from 'styled-components'

import { ExpandCollapse, Input, DebouncedForm, UploadImage } from '@openbox-app-shared'
import dashboardIcons from '../../../icons'
import CopyUrl from '../../../components/CopyUrl'
import TabBar from '../../../components/TabBar'
import Embedder from './Embedder'

const StyledComponent = styled.div`
  .tab-container > * {
    max-width: 26.5rem;
  }
  .tab-container > * + * {
    margin-top: 1rem;
  }
`

const tab = {
  id: 'deploy',
  name: (context) => 'Share',
  inlineIcon: dashboardIcons.deploy,
  to: (context) => `/me/flows/${context.state.application.id}/deploy`,
  child: function Child (context, extraProps) {
    const { application } = context.state
    const { viewSubroot } = context.props.match.params

    const liveLink = context.getLink(application, undefined, false, false)
    if (!liveLink) {
      return null
    }

    const tabs = [
      {
        id: 'link',
        name: 'Link',
        inlineIcon: dashboardIcons.link,
        to: application ? `/me/flows/${context.state.application.id}/deploy/link` : undefined,
        child: <>
          <ExpandCollapse
            text='Home screen'
          >
            <DebouncedForm {...extraProps.debouncedProps}>
              <UploadImage
                value={application.socialMedia.readFrom('homeScreenIcon')}
                show
                color='#26de81'
                onChange={({ url }) => {
                  application.socialMedia.writeTo('homeScreenIcon', url)
                  extraProps.debouncedProps.onChange({ application })
                }}
              />
              <hr />
              <Input
                name='homeScreenName'
                className='not-wide'
                storageSectorName='socialMedia'
                storageSector={application.socialMedia}
                label='Name'
                value={application.socialMedia.readFrom('homeScreenName')}
              />
            </DebouncedForm>
          </ExpandCollapse>
          <ExpandCollapse
            text='Social media'
          >
            <DebouncedForm {...extraProps.debouncedProps}>
              <UploadImage
                value={application.socialMedia.readFrom('image')}
                show
                color='#26de81'
                onChange={({ url }) => {
                  application.socialMedia.writeTo('image', url)
                  extraProps.debouncedProps.onChange({ application })
                }}
              />
              <hr />
              <Input
                name='description'
                storageSectorName='socialMedia'
                storageSector={application.socialMedia}
                label='Description'
                value={application.socialMedia.readFrom('description')}
              />
            </DebouncedForm>
          </ExpandCollapse>
        </>
      },
      {
        id: 'embed',
        name: 'Website pop up',
        inlineIcon: dashboardIcons.embed,
        to: application ? `/me/flows/${context.state.application.id}/deploy/embed` : undefined,
        child: (() => {
          return (
            <Embedder
              application={application}
              previewLink={liveLink}
              onUpdate={application => {
                extraProps.debouncedProps.onChange({ application })
              }}
            />
          )
        })()
      }
    ]

    return (
      <StyledComponent>
        <TabBar
          selectedTab={viewSubroot}
          tabs={tabs}
        />
      </StyledComponent>
    )
  }
}

export default tab
