/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { icons, _, isUuid, Form, Label, Banner, Input, Modal, Button } from '@openbox-app-shared'

import { log } from '../../../log'
import ApplicationChooser from '../../ApplicationChooser'

const StyledModalStep1 = styled.div`
  .buttons {
    text-align: center;
    > * {
      margin-top: 1rem;
      display: inline-block;
    }
  }
`

function ModalStep1 ({ user, onUpdate, onDone, onClose, applicationIds, slName, canCreate, isWorking }) {
  const [stuff, setStuff] = useState()
  useEffect(() => {
    async function getStuff () {
      const applications = await window.sticky.applications.getAll()
      const applicationIdsNotAssignedToStickypay = applications
        .filter(a => a.baseSettingsRender !== 'stickypay')
      setStuff({
        applicationIdsNotAssignedToStickypay
      })
      if (applicationIdsNotAssignedToStickypay) {
        onUpdate({ 'applicationIds': [applicationIdsNotAssignedToStickypay[0].id] })
      } else {
        onUpdate({ 'applicationIds': [] })
      }
    }
    !stuff && getStuff()
  })
  if (!stuff) {
    return null
  }
  return (
    <Modal onClose={onClose}>
      <StyledModalStep1>
        <Form
          onChange={(k, v) => onUpdate({ [k]: v })}
          onSubmit={onDone}
        >
          {stuff.applicationIdsNotAssignedToStickypay.length === 0 && (
            <Banner>
              <p>You need a flow without this template:</p>
              <p><strong>Take a payment</strong></p>
            </Banner>
          )}
          <Input
            name='slName'
            label='Name'
            value={slName}
          />
          {stuff.applicationIdsNotAssignedToStickypay.length > 0 && (
            <>
              <Label>Flow</Label>
              <ApplicationChooser
                label='Flow'
                name='applicationIds'
                applicableEntities={stuff.applicationIdsNotAssignedToStickypay}
                selected={applicationIds}
                onChange={a => onUpdate({ applicationIds: a })}
              />
            </>
          )}
        </Form>
        <div className='buttons'>
          <Button
            onClick={onDone}
            icon={icons.inverted.check}
            disabled={!canCreate || isWorking}
          >
            {isWorking ? 'Creating...' : 'Create'}
          </Button>
        </div>
      </StyledModalStep1>
    </Modal>
  )
}
ModalStep1.propTypes = {
  user: PropTypes.object,
  canCreate: PropTypes.bool,
  slName: PropTypes.string,
  onUpdate: PropTypes.func,
  onDone: PropTypes.func,
  onClose: PropTypes.func
}

function getCanCreate (payload) {
  return [
    payload.slName.length > 0,
    isUuid(payload.applicationIds[0])
  ]
    .every(e => e)
}

export default {
  trigger: ({ setState }) => {
    setState({ CREATE_SHORT_LINK: { stage: 1, isWorking: false } })
  },
  render: function ThisAction ({ user, state, setState, dispatch }) {
    const { CREATE_SHORT_LINK } = state
    if (!CREATE_SHORT_LINK) {
      return null
    }
    const { stage, isWorking } = CREATE_SHORT_LINK
    let [payload, setPayload] = useState({ slName: '', applicationIds: [] })
    log('CREATE_SHORT_LINK->render', { user, payload })
    const canReallyCreate = getCanCreate(payload)
    return (
      <>
        {stage === 1 && <ModalStep1
          user={user}
          canCreate={canReallyCreate}
          {...payload}
          isWorking={isWorking}
          onUpdate={o => {
            setPayload({
              ...payload,
              ...o
            })
          }}
          onDone={async () => {
            if (!canReallyCreate) {
              return
            }
            try {
              setState({
                CREATE_SHORT_LINK: {
                  ...CREATE_SHORT_LINK,
                  isWorking: true
                }
              })
              const shortLink = await window.sticky.shortLinks.create({ whichApplicationId: payload.applicationIds[0], type: 'REDIRECT', name: payload.slName })
              dispatch('CREATE_SHORT_LINK_GOOD', { shortLink })
              setState({
                CREATE_SHORT_LINK: undefined
              })
            } catch ({ message }) {
              window.sticky.applications.blocks.showError(message, true)
              setState({
                CREATE_SHORT_LINK: {
                  ...CREATE_SHORT_LINK,
                  isWorking: false
                }
              })
            }
          }}
          onClose={() => {
            setState({
              CREATE_SHORT_LINK: undefined
            })
          }}
        />}
      </>
    )
  }
}
