import isEmailValid from './isEmailValid/isEmailValid'

const BASE = {
  getValue: _ => _
}

export const sessionKeysAndHowToValidateThem = [
  {
    ...BASE,
    name: 'name',
    text: 'What\'s your name?',
    textT: 'SK_name',
    isUserConfigurable: true,
    type: 'text',
    isValid: (value) => typeof value === 'string' && value.length > 0,
    shouldAlwaysConfirm: false,
    shouldPersistToCus: true,
    shouldWriteToStickypay: true,
    get defaultValue() { return undefined }
  },
  {
    ...BASE,
    name: 'companyName',
    text: 'Where do you work?',
    textT: 'SK_companyName',
    isUserConfigurable: true,
    type: 'text',
    isValid: (value) => typeof value === 'string' && value.length > 0,
    shouldAlwaysConfirm: false,
    shouldPersistToCus: true,
    shouldWriteToStickypay: true,
    get defaultValue() { return undefined }
  },
  {
    ...BASE,
    name: 'email',
    text: 'What\'s your email?',
    textT: 'SK_email',
    isUserConfigurable: true,
    type: 'text',
    isValid: (value) => isEmailValid(value),
    shouldAlwaysConfirm: false,
    shouldPersistToCus: true,
    shouldWriteToStickypay: true,
    get defaultValue() { return undefined }
  },
  {
    ...BASE,
    name: 'phone',
    text: 'What\'s your phone number?',
    textT: 'SK_phone',
    isUserConfigurable: true,
    type: 'text',
    isValid: (value) => typeof value === 'string' && value.length > 0,
    shouldAlwaysConfirm: false,
    shouldPersistToCus: true,
    shouldWriteToStickypay: true,
    get defaultValue() { return undefined }
  },
  {
    ...BASE,
    name: 'collectionTime',
    text: 'Collection time?',
    textT: 'SK_collectionTime',
    isUserConfigurable: true,
    type: 'text',
    isValid: () => true,
    shouldAlwaysConfirm: true,
    shouldPersistToCus: false,
    shouldWriteToStickypay: false,
    get defaultValue() {
      return {
        label: 'Collection time',
        days: [0,1,2,3,4,5],
        minimumWait: 15,
        frequency: 15,
        times: '480--TO--960'
      }
    }
  },
  {
    ...BASE,
    name: 'address',
    text: 'What\'s your address?',
    textT: 'SK_address',
    isUserConfigurable: true,
    type: 'text',
    isValid: (value, values) => (typeof value === 'string' && value.length > 0),
    shouldAlwaysConfirm: true,
    shouldPersistToCus: true,
    shouldWriteToStickypay: true,
    get defaultValue() { return undefined }
  },
  {
    ...BASE,
    name: 'extra',
    text: 'Special requirements',
    textT: 'SK_extra',
    isUserConfigurable: true,
    type: 'boolean',
    isValid: (value) => true,
    shouldAlwaysConfirm: true,
    shouldPersistToCus: false,
    shouldWriteToStickypay: true,
    get defaultValue() { return '' }
  },
  {
    ...BASE,
    name: 'userPrivateKey',
    text: 'Private key',
    textT: 'SK_userPrivateKey',
    isUserConfigurable: false,
    type: 'text',
    isValid: (value) => true,
    shouldAlwaysConfirm: false,
    shouldPersistToCus: false,
    shouldWriteToStickypay: true,
    get defaultValue() { return undefined }
  }
]

export const sessionKeysAndHowToValidateThemByKey = {
  name: sessionKeysAndHowToValidateThem.find(k => k.name === 'name'),
  companyName: sessionKeysAndHowToValidateThem.find(k => k.name === 'companyName'),
  email: sessionKeysAndHowToValidateThem.find(k => k.name === 'email'),
  phone: sessionKeysAndHowToValidateThem.find(k => k.name === 'phone'),
  address: sessionKeysAndHowToValidateThem.find(k => k.name === 'address'),
  collectionTime: sessionKeysAndHowToValidateThem.find(k => k.name === 'collectionTime'),
  extra: sessionKeysAndHowToValidateThem.find(k => k.name === 'extra'),
  userPrivateKey: sessionKeysAndHowToValidateThem.find(k => k.name === 'userPrivateKey')
}
