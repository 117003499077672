/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { icons, _, isUuid, Form, Label, Banner, Input, PriceInput, Dropdown, Modal, Button } from '@openbox-app-shared'

import { log } from '../../../log'
import ApplicationChooser from '../../ApplicationChooser'

const StyledModal = styled.div`
  .buttons {
    text-align: center;
    > * {
      margin-top: 1rem;
      display: inline-block;
    }
  }
  .component--input.type--price input {
    max-width: 128px;
  }
`

function ThisModal ({ user, applicationIds, canCreate, total, totalCpa, cpaPeriod, userPaymentId, onUpdate, onDone, onClose, isWorking }) {
  const [stuff, setStuff] = useState()
  useEffect(() => {
    async function getStuff () {
      const applications = await window.sticky.applications.getAll()
      const applicationIdsAssignedToStickypay = applications
        .filter(a => a.baseSettingsRender === 'stickypay')
      setStuff({
        applicationIdsAssignedToStickypay
      })
      onUpdate({ 'applicationIds': applicationIdsAssignedToStickypay.length > 0 ? [applicationIdsAssignedToStickypay[0].id] : [] })
    }
    !stuff && getStuff()
  })
  if (!stuff) {
    return null
  }
  return (
    <Modal onClose={onClose}>
      <StyledModal>
        <Form
          onChange={(k, v) => onUpdate({ [k]: v })}
          onSubmit={onDone}
        >
          <PriceInput
            name='total'
            label='Total'
            value={total}
          />
          <Input
            name='userPaymentId'
            label='Your ID'
            value={userPaymentId}
          />
          {/* <PriceInput
            name='totalCpa'
            label={`Pay every ${_(cpaPeriod)}`}
            value={totalCpa}
            disabled
          />
          <Dropdown
            name='cpaPeriod'
            label='Period'
            items={window.sticky.Stickypay.CPA_PERIODS}
            selected={cpaPeriod}
            disabled
          /> */}
          {stuff.applicationIdsAssignedToStickypay.length === 0 && (
            <Banner>
              <p>You need a flow with this template:</p>
              <p><strong>Take a payment</strong></p>
            </Banner>
          )}
          {stuff.applicationIdsAssignedToStickypay.length > 0 && (
            <>
              <Label>Flow</Label>
              <ApplicationChooser
                label='Flow'
                name='applicationIds'
                applicableEntities={stuff.applicationIdsAssignedToStickypay}
                selected={applicationIds}
                onChange={a => onUpdate({ applicationIds: a })}
              />
            </>
          )}
        </Form>
        <div className='buttons'>
          <Button
            onClick={onDone}
            icon={icons.inverted.check}
            disabled={!canCreate || isWorking}
          >
            {isWorking ? 'Creating...' : 'Create'}
          </Button>
        </div>
      </StyledModal>
    </Modal>
  )
}
ThisModal.propTypes = {
  user: PropTypes.object,
  canCreate: PropTypes.bool,
  total: PropTypes.number,
  totalCpa: PropTypes.number,
  cpaPeriod: PropTypes.string,
  onUpdate: PropTypes.func,
  onDone: PropTypes.func,
  onClose: PropTypes.func
}

function getCanCreate (payload) {
  return [
    isUuid(payload.applicationIds[0])
  ]
    .every(e => e)
}

export default {
  trigger: ({ setState, total = 500, totalCpa = 1000, cpaPeriod = 'MONTH', sessionId }) => {
    setState({ CREATE_PAYMENT: { total, totalCpa, cpaPeriod, sessionId, isWorking: false } })
  },
  render: function ThisAction ({ user, state, setState, dispatch }) {
    const { CREATE_PAYMENT } = state
    if (!CREATE_PAYMENT) {
      return null
    }
    const { total, totalCpa, cpaPeriod, userPaymentId, sessionId, isWorking } = CREATE_PAYMENT
    let [payload, setPayload] = useState({ total, totalCpa, cpaPeriod, applicationIds: [], sessionId })
    log('CREATE_PAYMENT->render', { user, payload })
    const canReallyCreate = getCanCreate(payload)
    return (
      <>
        <ThisModal
          user={user}
          canCreate={canReallyCreate}
          applicationIds={payload.applicationIds}
          total={payload.total}
          totalCpa={payload.totalCpa}
          cpaPeriod={payload.cpaPeriod}
          userPaymentId={payload.userPaymentId}
          isWorking={isWorking}
          onUpdate={o => {
            setPayload({
              ...payload,
              ...o
            })
          }}
          onDone={async () => {
            if (!canReallyCreate) {
              return
            }
            const paymentPayload = {
              total: payload.total,
              sessionId,
              userPaymentId: payload.userPaymentId
              // totalCpa: payload.totalCpa,
              // cpaPeriod: payload.cpaPeriod
            }
            try {
              setState({
                CREATE_PAYMENT: {
                  ...CREATE_PAYMENT,
                  isWorking: true
                }
              })
              const payment = await window.sticky.pay.create(payload.applicationIds[0], paymentPayload)
              dispatch('CREATE_PAYMENT_GOOD', { payment })
              setState({
                CREATE_PAYMENT: undefined
              })
            } catch ({ message }) {
              window.sticky.applications.blocks.showError(message, true)
              setState({
                CREATE_PAYMENT: {
                  ...CREATE_PAYMENT,
                  isWorking: false
                }
              })
            }
          }}
          onClose={() => {
            setState({
              CREATE_PAYMENT: undefined
            })
          }}
        />
      </>
    )
  }
}
